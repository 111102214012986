import {
  LOAD_COMMENTS,
  LOAD_COMMENTS_ERROR,
  LOAD_COMMENTS_LOADING,
  INSERT_COMMENT,
  CHANGE_REACT,
  DELETE_COMMENT,
  INSERT_REPLY,
  DELETE_REPLY,
  CHOOSE_POLL_OPTION,
  UPDATE_POST,
  UPDATE_COMMENT,
  UPDATE_REPLY,
  DELETE_CHOICE_POLL_OPTION,
  UPDATE_POLL_OPTION,
} from "../actions/types";
import { createReducer } from "@reduxjs/toolkit";
const initialState = {
  error: "",
  loading: false,
  data: {
    byId: {},
    allIds: [],
  },
  count: 0,
  allPages: 0,
  thisPage: 0,
  post: null,
};
const commentsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(UPDATE_COMMENT, (s, { payload }) => {
      const isFound = s.data.byId[payload?.id];

      if (isFound) {
        s.data.byId[payload?.id] = {
          ...s.data.byId[payload?.id],
          ...payload,
        };
      }
    })
    .addCase(UPDATE_POST, (s, { payload }) => {
      let postFound = s?.post?.id === payload?.id;
      if (postFound) {
        s.post = { ...s.post, ...payload };
      }
    })
    .addCase(CHOOSE_POLL_OPTION, (s, { payload }) => {
      let postFound = s?.post?.id === payload?.postId;
      if (postFound) {
        s.post.type.poll = payload?.poll;
      }
    })
    .addCase(UPDATE_POLL_OPTION, (s, { payload }) => {
      let postFound = s?.post?.id === payload?.postId;
      if (postFound) {
        const questionIndex = s.post.type.poll?.questions?.findIndex(
          (question) => question.id === payload?.questionId
        );

        const optionIndex = s.post.type.poll.questions[
          questionIndex
        ]?.options?.findIndex((option) => option.id === payload?.option?.id);

        s.post.type.poll.questions[questionIndex].options[optionIndex] =
          payload?.option;
      }
    })
    .addCase(DELETE_CHOICE_POLL_OPTION, (s, { payload }) => {
      let postFound = s?.post?.id === payload?.postId;
      if (postFound) {
        const qIndex = s.post.type.poll?.questions?.findIndex(
          (e) => e?.id === payload?.questionId
        );
        s.post.type.poll.questions[qIndex].myChosenOption = null;
      }
    })
    .addCase(DELETE_REPLY, (s, { payload }) => {
      const index = s.data.byId[payload?.commentId]?.replies.findIndex(
        (r) => r?.id === payload?.replyId
      );

      if (index > -1) {
        s.data.byId[payload?.commentId]?.replies.splice(index, 1);
      }
    })
    .addCase(UPDATE_REPLY, (s, { payload }) => {
      const index = s.data.byId[payload?.commentId]?.replies.findIndex(
        (r) => r?.id === payload?.id
      );
      if (index > -1) {
        s.data.byId[payload?.commentId].replies[index] = {
          ...(s.data.byId[payload?.commentId].replies[index] || {}),
          ...payload,
        };
      }
    })
    .addCase(INSERT_REPLY, (s, { payload }) => {
      s.data.byId[payload?.commentId]?.replies?.unshift(payload);
      if (typeof s.data.byId[payload?.commentId]?.repliesCount == "number") {
        s.data.byId[payload?.commentId].repliesCount++;
      }
    })
    .addCase(DELETE_COMMENT, (s, { payload }) => {
      delete s.data.byId[payload];
      const index = s.data.allIds.indexOf(payload);
      if (index > -1) {
        s.data.allIds.splice(index, 1);
      }
    })
    .addCase(CHANGE_REACT, (s, { payload }) => {
      const { deleteReact, postId, reactionTypeId } = payload;

      if (postId === s?.post?.id) {
        const userReaction = s.post["userReaction"];
        if (deleteReact) {
          s.post["userReaction"] = false;
          s.post["totalReactionCount"]--;
          s.post["reacts"]["total"]--;
          s.post["reacts"][userReaction]--;
        } else {
          s.post["userReaction"] = reactionTypeId;
          if (!userReaction) {
            s.post["reacts"]["total"]++;
            s.post["totalReactionCount"]++;
            s.post["reacts"][reactionTypeId]++;
          } else {
            s.post["reacts"][userReaction]--;
            s.post["reacts"][reactionTypeId]++;
            s.post["userReaction"] = reactionTypeId;
          }
        }
      }
    })
    .addCase(LOAD_COMMENTS_LOADING, (s) => {
      s.loading = true;
      s.error = false;
    })
    .addCase(LOAD_COMMENTS_ERROR, (s, { payload }) => {
      s.loading = false;
      s.error = payload;
    })
    .addCase(LOAD_COMMENTS, (s, { payload }) => {
      if (s.post?.id !== payload?.post?.id) {
        s.post = { ...payload.post, id: parseInt(payload.post?.id) };
      }

      s.data.byId =
        payload?.thisPage === 1
          ? payload.byId
          : { ...s.data?.byId, ...payload?.byId };
      s.data.allIds =
        payload?.thisPage === 1
          ? payload.allIds
          : [...s.data?.allIds, ...payload?.allIds];
      s.allPages = payload.allPages;
      s.thisPage = payload.thisPage;
      s.loading = false;
    })
    .addCase(INSERT_COMMENT, (s, { payload }) => {
      s.data.byId[payload?.id] = {
        ...payload,
        replies: [],
        repliesCount: 0,
      };
      s.data.allIds.unshift(payload?.id);
      if (typeof s.post.commentsCount === "number") {
        s.post.commentsCount++;
      }
    });
});

export default commentsReducer;
