import { getRequest } from "../connection/network";

const appApis = {
  companyBySubdomain: "company/sub-domain",
};

export const getCompanyBySubdomain = async ({ subDomain, token }) => {
  try {
    const { data } = await getRequest({
      query: { subDomain },
      token,
      endPoint: appApis.companyBySubdomain,
    });
    return {
      company: data.data,
    };
  } catch (error) {
    return {
      company: null,
    };
  }
};
