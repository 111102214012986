import { socket } from "../connection/socket";
import { addMessagToLocalStorage, deleteMessageLO } from "../storage/chats";
import store from "../store";
import { insertNewMessageAction } from "../store/actions/chat";
import { REMOVE_MESSAGES } from "../store/actions/types";

import { sendMedia } from "./chat";
const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export const sendMessageSocket = async ({
  text,
  chatId,
  sender,
  userId,
  files = [],
  selectedReply,
  callBack,
  botPostback,
  delayedFor,
  stickerId,
  pollData,
}) => {
  let newChat = false;
  if (!chatId) {
    newChat = true;
  }
  let feMark = Date.now();
  let message = {
    text: text ? text.trim() : "",
    chatId: chatId,
    feMark,
    pollData,
    senderId: sender?.id,
    sender: {
      id: sender.id,
      firstName: sender.firstName,
      lastName: sender?.lastName,
      mobile: sender?.mobile,
      image: sender?.image,
      color: sender?.color,
    },
    type: null,
    status: "loading",
    attachments: files?.map((file) => {
      return {
        media: file.media,
        type: file.file?.type,
        size: file?.file?.size,
        name: file?.file?.name,
      };
    }),
    repliedTo: selectedReply,
    delayedFor,
    createdAt: String(new Date()),
  };

  if (stickerId) delete message?.text;
  insertNewMessageAction(message, true);

  await delay(20);

  if (files.length > 0) {
    const formData = new FormData();
    files.map((f) => {
      if (f.file) return formData.append("files", f.file);
      return f;
    });
    text && formData.append("text", text);
    feMark && formData.append("feMark", feMark);
    !newChat && formData.append("chatId", chatId);
    selectedReply && formData.append("replyToMessageUuId", selectedReply.uuid);
    addMessagToLocalStorage(chatId, message);
    if (callBack) callBack();
    return sendMedia(formData, (error) => {
      if (callBack) callBack(error);
    });
  }

  /*  if (selectedReply) {
    if (callBack) callBack();
    return socket.sendMessage("reply_message", {
      replyToMessageUuId: selectedReply.uuid,
      data: {
        text,
        feMark,
      },
    });
  } */
  if (callBack) callBack();
  return socket.sendMessage(newChat ? "send_to_user_id" : "send_message", {
    text,
    chatId,
    userId,
    feMark,
    botPostback,
    delayedFor,
    stickerId,
    replyToMessageUuId: selectedReply?.uuid,
    pollData,
  });
};

export const sendForwardMessageSocket = ({ messageUUID, chatsId }) => {
  let message = {
    messageUUID,
    chatsId,
  };

  return socket.sendMessage("forward_message", message);
};

export const joinThread = async (threadChatId) => {
  socket.sendMessage("thread_leave");
  return socket.sendMessage("thread_join", { threadChatId });
};

export const deleteMessageIO = async (data) => {
  const callBack = (data) => {
    const { deleteForMe } = data;
    const { chatId, uuid } = deleteForMe;

    if (chatId && uuid) {
      store?.dispatch({ type: REMOVE_MESSAGES, payload: { chatId, uuid } });
      deleteMessageLO({ uuids: uuid, chatId });
    }
  };
  return socket.sendMessage("delete_message", data, callBack);
};

export const starMessage = ({ uuid, starred }) => {
  socket.sendMessage(`star_message`, { uuid, starred });
};

export const reactToMessageSocket = ({
  uuid,
  chatId,
  deleteReact,
  reactionTypeId,
}) => {
  return socket.sendMessage(`message_react`, {
    uuid,
    chatId,
    deleteReact,
    reactionTypeId,
  });
};
