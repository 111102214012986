import { listNotificationsActionService, listNotificationsService, listNotificationsWithoutActionService } from "../../../services/notifications";
import { socket } from "../../../connection/socket";

import {
  LIST_NOTIFICATIONS,
  LIST_NOTIFICATIONS_ERROR,
  LIST_NOTIFICATIONS_LOADING,
  INSERT_NEW_NOTIFICATION,
  BUZZED,
  START_SHAKING,
  STOP_SHAKING,
  CHANGE_EVENT,
  CHANGE_TASK,
  LIST_NOTIFICATIONS_NO_ACTION_ERROR,
  LIST_NOTIFICATIONS_NO_ACTION_LOADING,
  LIST_NOTIFICATIONS_ACTION,
  LIST_NOTIFICATIONS_ACTION_ERROR,
  LIST_NOTIFICATIONS_ACTION_LOADING,
  LIST_NOTIFICATIONS_NO_ACTION,
} from "../types";
import SnackUtils from "../../../shared/snacks";
import store from "../..";
import { notifySound } from "../../../shared/audios";
import { updateEventStatusService } from "../../../services/event";
import { updateTaskStatusService } from "../../../services/tasks";
export const getNotificationsAction =
  ({ page, limit, text, type, chatId }) =>
  (dispatch) => {
    dispatch({ type: LIST_NOTIFICATIONS_LOADING });
    return listNotificationsService({
      page,
      limit,
    }).then((d) => {
      if (d.error) {
        dispatch({
          type: LIST_NOTIFICATIONS_ERROR,
          payload: d.error,
        });
      }
      dispatch({
        type: LIST_NOTIFICATIONS,
        payload: d,
      });
    });
  };

  export const getNotificationsActionAction =
  ({ page, limit, text, type, chatId }) =>
  (dispatch) => {
    dispatch({ type: LIST_NOTIFICATIONS_ACTION_LOADING });
    return listNotificationsActionService({
      page,
      limit,
    }).then((d) => {
      if (d.error) {
        dispatch({
          type: LIST_NOTIFICATIONS_ACTION_ERROR,
          payload: d.error,
        });
      }
      dispatch({
        type: LIST_NOTIFICATIONS_ACTION,
        payload: d,
      });
    });
  };

  export const getNotificationsNoActionAction =
  ({ page, limit, text, type, chatId }) =>
  (dispatch) => {
    dispatch({ type: LIST_NOTIFICATIONS_NO_ACTION_LOADING });
    return listNotificationsWithoutActionService({
      page,
      limit,
    }).then((d) => {
      if (d.error) {
        dispatch({
          type: LIST_NOTIFICATIONS_NO_ACTION_ERROR,
          payload: d.error,
        });
      }
      dispatch({
        type: LIST_NOTIFICATIONS_NO_ACTION,
        payload: d,
      });
    });
  };


  export const changeEventAction =
  ({ eventId, status, cb }) =>
  (dispatch) => {
    return updateEventStatusService({ eventId, status }).then((res) => {
      const { error, data } = res;
      cb && cb();
      if (error) {
        return console.error(error);
      }
      const { status: tStatus = {} } = data;
      dispatch({
        type: CHANGE_EVENT,
        payload: { eventId, ...tStatus },
      });
    });
  };

export const changeTaskAction=
({ taskId, progress, status, cb }) =>
(dispatch) => {
  return updateTaskStatusService({ taskId, status, progress }).then((res) => {
    const { error, data } = res;
    cb && cb();
    if (error) {
      return console.error(error);
    }
    const { status: tStatus = {} } = data;
    dispatch({
      type: CHANGE_TASK,
      payload: { taskId, ...tStatus },
    });
  });
};

export const totalUnReadAction = (totalUnRead) => {
  if (totalUnRead !== 0) {
    socket.sendMessage("read_notifications");
  }
};

export const insertNewNotification = (notification) => (dispatch) => {
  notification?.title && SnackUtils.info(notification?.title);

  dispatch({ type: INSERT_NEW_NOTIFICATION, payload: notification });
};

export const buzzHandler =
  (data = {}) =>
  (dispatch) => {
    const stateChat = store.getState()?.singleChat?.chat;
    const { chatId, sender } = data;

    let isSame = stateChat?.id == chatId;
    if (!isSame) {
      isSame = sender.id === stateChat?.otherUser?.id;
    }

    if (!isSame) {
      notifySound();
      SnackUtils.info(
        `${sender.firstName} sent you a buzz, Hurry and reply back!`
      );
    } else shakeHandler(chatId);
  };

export const shakeHandler = (chatId) => {
  store.dispatch({ type: START_SHAKING, payload: chatId });

  setTimeout(() => {
    store.dispatch({ type: START_SHAKING, payload: null });
  }, [1000]);
};
