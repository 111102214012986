import {
  LIMIT_PAGE_LOADING,
  LOAD_LIMIT_PAGE,
  LOAD_LIMIT_PAGE_ERROR,
  ADD_LIMIT_PAGE,
  EDIT_LIMIT_PAGE,
} from "../actions/types";
import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  data: {},
  thisPage: 0,
  count: 0,
  allPages: 0,
  error: false,
  loading: false,
};

const limitPageReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(LIMIT_PAGE_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(LOAD_LIMIT_PAGE, (state, { payload }) => {
      state.data = payload?.data?.length ? payload?.data[0] : {};
      state.thisPage = payload.thisPage;
      state.nextPage = payload.nextPage;
      state.count = payload.count;

      state.loading = false;
    })
    .addCase(LOAD_LIMIT_PAGE_ERROR, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    })
    .addCase(ADD_LIMIT_PAGE, (state, { payload }) => {
      state.data = payload;
      state.loading = false;
      state.error = false;
    })
    .addCase(EDIT_LIMIT_PAGE, (state, { payload }) => {
      state.data = { ...state.data, ...payload };
    });
});

export default limitPageReducer;
