import {
  GET_MEMBER_TYPES_LOADING,
  GET_MEMBER_TYPES_ERROR,
  GET_MEMBER_TYPES,
} from "../actions/types";
import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  memberTypes: {
    error: "",
    loading: false,
    data: [],
    thisPage: 0,
    nextPage: 0,
    hasMore: true,
    count: 0,
  },
};

const specialMemberReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(GET_MEMBER_TYPES_LOADING, (state) => {
      state.memberTypes.loading = true;
    })
    .addCase(GET_MEMBER_TYPES_ERROR, (state, { payload }) => {
      state.memberTypes.error = payload;
    })
    .addCase(GET_MEMBER_TYPES, (state, { payload }) => {
      state.memberTypes.data =
        payload?.thisPage === 1
          ? payload?.data
          : [...state?.memberTypes?.data, ...payload?.data];
      state.memberTypes.thisPage = payload.thisPage;
      state.memberTypes.nextPage = payload.nextPage;
      state.memberTypes.hasMore = payload.hasMore;
      state.memberTypes.count = payload.count;
      state.memberTypes.loading = false;
    });
});

export default specialMemberReducer;
