import notifyAudio from "../Assets/audio/notification.mp3";
export const notifySound = () => {
try {
    let notificationAudio = new Audio(notifyAudio);
    notificationAudio.play().catch(() => {});
    notificationAudio.onended((e) => notificationAudio.pause());
} catch (error) {
    
}
};
