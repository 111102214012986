import grey from "@mui/material/colors/grey";
import { dark, darkBaseline } from "./dark";
import { light, lightBaseline } from "./light";

const getIsLight = (color) => {
  if (color) {
    const hex = color.replace("#", "");
    const c_r = parseInt(hex.substring(0, 0 + 2), 16);
    const c_g = parseInt(hex.substring(2, 2 + 2), 16);
    const c_b = parseInt(hex.substring(4, 4 + 2), 16);
    const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000;
    return brightness > 155;
  }
  return;
};

const getDesignTokens = ({ mode, pageTheme }) => {
  return {
    typography: {
      allVariants: {
        fontFamily: "Nunito",
        textTransform: "none",
      },
    },
    palette: {
      mode,
      ...(mode === "light"
        ? {
            ...light({
              primColor: pageTheme?.primColor,
              secColor: pageTheme?.secColor,
              primContrastText: getIsLight(pageTheme?.primColor) && "#000",
              secContrastText: getIsLight(pageTheme?.secColor) && "#000",
            }),
          }
        : {
            ...dark({
              primColor: pageTheme?.primColor,
              secColor: pageTheme?.secColor,
            }),
          }),
    },
    components: {
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            marginTop: 0,
            height: 0,
          },
        },
      },
      MuiTextareaAutosize: {
        styleOverrides: { root: { background: "#202225" } },
      },
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            fontFamily: "Nunito",
            ...(mode === "light" ? lightBaseline : darkBaseline),
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            boxShadow: "none",
            border: mode === "light" ? "1px solid" : 0,
            borderColor: mode === "light" ? grey[200] : grey[800],
            fontFamily: "Nunito",
          },
        },
      },
      MuiFab: {
        styleOverrides: {
          root: {
            boxShadow: "none",
            border: 0,
            borderColor: mode === "light" ? grey[200] : grey[800],
            fontFamily: "Nunito",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontWeight: "bold",
            borderRadius: "8px",
          },
        },
      },
    },
  };
};

export default getDesignTokens;
