export const APP_CONFIG = "APP_CONFIG";
export const CREATE_PRODUCT = "CREATE_PRODUCT";

export const USER_LOADED = "USER_LOADED";
export const USER_LOADING = "USER_LOADING";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const UPDATE_PROFILE_LOADING = "UPDATE_PROFILE_LOADING";
export const UPDATE_PROFILE_ERROR = "UPDATE_PROFILE_ERROR";
export const GET_FIREBASE_TOKEN = "GET_FIREBASE_TOKEN";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const DELETE_CHAT = "DELETE_CHAT";
export const SET_MESSAGE_DELETED = "SET_MESSAGE_DELETED";
export const STARR_MESSAGE_CHANGE = "STARR_MESSAGE_CHANGE";
export const CHANGE_MESSAGE_REACT = "CHANGE_MESSAGE_REACT";
export const REMOVE_MESSAGES = "REMOVE_MESSAGES";
///Global
export const SET_SIDE_LIST = "SET_SIDE_LIST";
export const SET_SIDE_TODO = "SET_SIDE_TODO";
export const SET_SIDE_EVENT = "SET_SIDE_EVENT";
export const SET_SIDE_TASK = "SET_SIDE_TASK";

export const TOGGLEDRAWER = "TOGGLEDRAWER";
export const SOCKETCONNECTED = "SOCKETCONNECTED";

//Chats
export const CHATS_LOADED = "CHATS_LOADED";
export const ADD_SINGLE_CHAT = "ADD_SINGLE_CHAT";
export const CHATS_LOADING = "CHATS_LOADING";
export const CHATS_ERROR = "CHATS_ERROR";
export const UPDATE_CHATS_WHEN_MESSAGE_GOT = "UPDATE_CHATS_WHEN_MESSAGE_GOT";
export const UPDATE_CHAT_UNSEEN_MESSAGE_COUNT =
  "UPDATE_CHAT_UNSEEN_MESSAGE_COUNT";
export const ADD_CHAT_MEMBERS = "ADD_CHAT_MEMBERS";
export const DELETE_CHAT_MEMBER = "DELETE_CHAT_MEMBER";
export const CHANGE_CHATS_TAB = "CHANGE_CHATS_TAB";

//Chat Threads
export const GET_THREADS = "GET_THREADS";
export const THREADS_LOADING = "THREADS_LOADING";
export const THREADS_ERROR = "THREADS_ERROR";

//SingleChat
export const SINGLE_CHAT_LOADED = "SINGLE_CHAT_LOADED";
export const SINGLE_CHAT_LOADING = "SINGLE_CHAT_LOADING";
export const SINGLE_CHAT_ERROR = "SINGLE_CHAT_ERROR";
export const SET_TEMP_CHAT = "SET_TEMP_CHAT";
export const INSERT_NEW_MESSAGE = "INSERT_NEW_MESSAGE";
export const CONVERT_MESSAGE = "CONVERT_MESSAGE";
export const MESSAGE_SENT = "MESSAGE_SENT";
export const INSERT_NEW_GROUP = "INSERT_NEW_GROUP";
export const UPDATE_CHAT_INFO = "UPDATE_CHAT_INFO";
export const EMPTY_SINGLE_CHAT = "EMPTY_SINGLE_CHAT";
export const CLEAR_CHAT_MESSAGES = "CLEAR_CHAT_MESSAGES";
//Contacts
export const CONTACTS_LOADED = "CONTACTS_LOADED";
export const CONTACTS_LOADING = "CONTACTS_LOADING";
export const CONTACTS_ERROR = "CONTACTS_ERROR";
export const CREATE_NEW_CONTACT = "CREATE_NEW_CONTACT";
export const DELETE_CONTACT = "DELETE_CONTACT";

export const ADD_USER_TO_CONTACT = "ADD_USER_TO_CONTACT";

/// Event Reducer
export const EVENTS_LOADING = "EVENTS_LOADING";
export const LOAD_EVENTS = "LOAD_EVENTS";
export const LOAD_EVENTS_ERROR = "LOAD_EVENTS_ERROR";
export const NEW_EVENT_LOADING = "NEW_EVENT_LOADING";
export const CREATE_NEW_EVENT = "CREATE_NEW_EVENT";
export const NEW_EVENT_ERROR = "NEW_EVENT_ERROR";

export const SINGLE_EVENT_LOADING = "SINGLE_EVENT_LOADING";
export const SINGLE_EVENT_ERROR = "SINGLE_EVENT_ERROR";
export const SINGLE_EVENT_LOAD = "SINGLE_EVENT_LOAD";
export const CHANGE_EVENT_STATUS = "CHANGE_EVENT_STATUS";
export const DELETE_EVENT = "DELETE_EVENT";
export const GET_EVENT_INVITEES = "GET_EVENT_INVITEES";
export const GET_EVENT_INVITEES_LOADING = "GET_EVENT_INVITEES_LOADING";
export const GET_EVENT_INVITEES_ERROR = "GET_EVENT_INVITEES_ERROR";
export const UPDATE_EVENT_INVITEES = "UPDATE_EVENT_INVITEES";

export const EVENT_LIVE_LOADING = "EVENT_LIVE_LOADING";
export const EVENT_LIVE_ERROR = "EVENT_LIVE_ERROR";
export const EVENT_LIVE_LOAD = "EVENT_LIVE_LOAD";

//Task Reducer

export const TASKS_LOADING = "TASKS_LOADING";
export const LOAD_TASKS = "LOAD_TASKS";
export const LOAD_TASKS_ERROR = "LOAD_TASKS_ERROR";
export const NEW_TASK_LOADING = "NEW_TASK_LOADING";
export const CREATE_NEW_TASK = "CREATE_NEW_TASK";
export const NEW_TASK_ERROR = "NEW_TASK_ERROR";

export const GET_SINGLE_TASK = "GET_SINGLE_TASK";
export const GET_SINGLE_TASK_LOADING = "GET_SINGLE_TASK_LOADING";
export const GET_SINGLE_TASK_ERROR = "GET_SINGLE_TASK_ERROR";

export const GET_DEPENDENT_TASKS = "GET_DEPENDENT_TASKS";
export const GET_DEPENDENT_TASKS_LOADING = "GET_DEPENDENT_TASKS_LOADING";
export const GET_DEPENDENT_TASKS_ERROR = "GET_DEPENDENT_TASKS_ERROR";

export const CHANGE_TASK_STATUS = "CHANGE_TASK_STATUS";
export const DELETE_TASK = "DELETE_TASK";

export const GET_TASK_ASSIGNEES = "GET_TASK_ASSIGNEES";
export const GET_TASK_ASSIGNEES_LOADING = "GET_TASK_ASSIGNEES_LOADING";
export const GET_TASK_ASSIGNEES_ERROR = "GET_TASK_ASSIGNEES_ERROR";
export const UPDATE_TASK_ASSIGNEES = "UPDATE_TASK_ASSIGNEES";
///Users

export const SET_CURRENT_USERS = "SET_CURRENT_USERS";
export const CHANGE_USER_STATUS = "CHANGE_USER_STATUS";

/// Page
export const GET_SINGLE_PAGE_ERROR = "GET_SINGLE_PAGE_ERROR";
export const GET_SINGLE_PAGE_LOADING = "GET_SINGLE_PAGE_LOADING";
export const GET_SINGLE_PAGE = "GET_SINGLE_PAGE";
export const DELETE_POST = "DELETE_POST";
export const GET_PAGE_BOT_LOADING = "GET_PAGE_BOT_LOADING";
export const GET_PAGE_BOT = "GET_PAGE_BOT";

export const UPDATE_PAGE_DATA = "UPDATE_PAGE_DATA";
export const OPEN_DESIGN_MODE = "OPEN_DESIGN_MODE";
export const UPDATE_TAB = "UPDATE_TAB";
export const CREATE_TAB = "CREATE_TAB";
export const DELETE_TAB = "DELETE_TAB";

// page cats
export const GET_PAGE_CATS = "GET_PAGE_CATS";
export const CREATE_PAGE_CATS = "CREATE_PAGE_CATS";
export const UPDATE_PAGE_CATS = "UPDATE_PAGE_CATS";
export const DELETE_PAGE_CATS = "DELETE_PAGE_CATS";

//POSTS
export const LOAD_POSTS = "LOAD_POSTS";
export const LOAD_POSTS_ERROR = "LOAD_POSTS_ERROR";
export const LOAD_POSTS_LOADING = "LOAD_POSTS_LOADING";
export const INSERT_POST = "INSERT_POST";
export const CHANGE_REACT = "CHANGE_REACT";
export const JOIN_POST_EVENT = "JOIN_POST_EVENT";
export const UPDATE_POST = "UPDATE_POST";
//Comments
export const LOAD_COMMENTS = "LOAD_COMMENTS";
export const LOAD_COMMENTS_ERROR = "LOAD_COMMENTS_ERROR";
export const LOAD_COMMENTS_LOADING = "LOAD_COMMENTS_LOADING";
export const INSERT_COMMENT = "INSERT_COMMENT";
export const DELETE_COMMENT = "DELETE_COMMENT";
export const UPDATE_COMMENT = "UPDATE_COMMENT";

///REPLIES
export const INSERT_REPLY = "INSERT_REPLY";
export const DELETE_REPLY = "DELETE_REPLY";
export const UPDATE_REPLY = "UPDATE_REPLY";

//Members
export const GET_PAGE_MEMBERS = "GET_PAGE_MEMBERS";
export const GET_PAGE_MEMBERS_LOADING = "GET_PAGE_MEMBERS_LOADING";
export const GET_PAGE_MEMBERS_ERROR = "GET_PAGE_MEMBERS_ERROR";

///Poll
export const CHOOSE_POLL_OPTION = "CHOOSE_POLL_OPTION";
export const DELETE_CHOICE_POLL_OPTION = "DELETE_CHOICE_POLL_OPTION";
export const CHOOSE_MESSAGE_POLL_OPTION = "CHOOSE_MESSAGE_POLL_OPTION";
export const UPDATE_POLL_OPTION = "UPDATE_POLL_OPTION";

///Reservations
export const GET_PAGE_RESERVATIONS_LOADING = "GET_PAGE_RESERVATIONS_LOADING";
export const GET_PAGE_RESERVATIONS_ERROR = "GET_PAGE_RESERVATIONS_ERROR";
export const GET_PAGE_RESERVATIONS = "GET_PAGE_RESERVATIONS";
///SPeakers
export const GET_PAGE_SPEAKERS_LOADING = "GET_PAGE_SPEAKERS_LOADING";
export const GET_PAGE_SPEAKERSE_ERROR = "GET_PAGE_SPEAKERSE_ERROR";
export const GET_PAGE_SPEAKERS = "GET_PAGE_SPEAKERS";

export const ADD_SPEAKER = "ADD_SPEAKER";
export const CHANGE_SPEAKER_RATE = "CHANGE_SPEAKER_RATE";
export const CHANGE_SPEAKER_BOOKMARK = "CHANGE_SPEAKER_BOOKMARK";

///SPonsors
export const GET_PAGE_SPONSORS_LOADING = "GET_PAGE_SPONSORS_LOADING";
export const GET_PAGE_SPONSORS_ERROR = "GET_PAGE_SPONSORS_ERROR";
export const GET_PAGE_SPONSORS = "GET_PAGE_SPONSORS";
export const ADD_SPONSOR = "ADD_SPONSOR";
export const EDIT_SPONSOR = "EDIT_SPONSOR";

///page chat
export const CHANGE_PAGE_CHAT = "CHANGE_PAGE_CHAT";
export const CHANGE_PAGE_TAB = "CHANGE_PAGE_TAB";

///Analytics
export const GET_PAGE_ANALYTICS_LOADING = "GET_PAGE_ANALYTICS_LOADING";
export const GET_PAGE_ANALYTICS = "GET_PAGE_ANALYTICS";
export const GET_PAGE_ANALYTICS_ERROR = "GET_PAGE_ANALYTICS_ERROR";

/// nOTIFICATIONS
export const LIST_NOTIFICATIONS = "LIST_NOTIFICATIONS";
export const LIST_NOTIFICATIONS_LOADING = "LIST_NOTIFICATIONS_LOADING";
export const LIST_NOTIFICATIONS_ERROR = "LIST_NOTIFICATIONS_ERROR";
export const INSERT_NEW_NOTIFICATION = "INSERT_NEW_NOTIFICATION";
export const UPDATE_UN_READ = "UPDATE_UN_READ";

///TODO
export const GET_TODO = "GET_TODO";
export const GET_TODO_ERROR = "GET_TODO_ERROR";
export const GET_TODO_LOADING = "GET_TODO_LOADING";
export const INSERT_TODO = "INSERT_TODO";
export const DELETE_TODO = "DELETE_TODO";
export const UPDATE_TODO = "UPDATE_TODO";

export const GET_SINGLE_TODO_LOADING = "GET_SINGLE_TODO_LOADING";
export const GET_SINGLE_TODO = "GET_SINGLE_TODO";
export const GET_SINGLE_TODO_ERROR = "GET_SINGLE_TODO_ERROR";
export const GET_TODO_CATEGORY_LOADING = "GET_TODO_CATEGORY_LOADING";
export const GET_TODO_CATEGORY_ERROR = "GET_TODO_CATEGORY_ERROR";
export const GET_TODO_CATEGORY = "GET_TODO_CATEGORY";
export const CREATE_TODO_CATEGORY = " ";

///Products
export const LIST_CATEGORIES = "LIST_CATEGORIES";
export const LIST_CATEGORIES_LOADING = "LIST_CATEGORIES_LOADING";
export const LIST_CATEGORIES_ERROR = "LIST_CATEGORIES_ERROR";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const ADD_CATEGORY = "ADD_CATEGORY";

export const LIST_PRODUCTS = "LIST_PRODUCTS";
export const LIST_PRODUCTS_LOADING = "LIST_PRODUCTS_LOADING";
export const LIST_PRODUCTS_ERROR = "LIST_PRODUCTS_ERROR";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const SET_CURRENT_CATEGORY = "SET_CURRENT_CATEGORY";
export const SET_CURRENT_ORGANIZATION = "SET_CURRENT_ORGANIZATION";

//cart
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const LIST_CART_LOADING = "LIST_CART_LOADING";
export const LIST_CART_ERROR = "LIST_CART_ERROR";
export const LIST_CART = "LIST_CART";

export const INCREASE_ITEM = "INCREASE_ITEM";
export const DECREASE_ITEM = "DECREASE_ITEM";

export const CHECKOUT = "CHECKOUT";

//Address
export const ADD_ADDRESS = "ADD_ADDRESS";
export const REMOVE_ADDRESS = "REMOVE_ADDRESS";
export const UPDATE_ADDRESS = "UPDATE_ADDRESS";
export const LIST_ADDRESS_LOADING = "LIST_ADDRESS_LOADING";
export const LIST_ADDRESS_ERROR = "LIST_ADDRESS_ERROR";
export const LIST_ADDRESS = "LIST_ADDRESS";

//Payment
export const CHOSE_ADDRESS = "CHOSE_ADDRESS";
export const CHANGE_NOTES = "CHANGE_NOTES";

export const BUZZED = "BUZZED";
export const START_SHAKING = "START_SHAKING";
export const STOP_SHAKING = "STOP_SHAKING";
//Orders
export const LIST_ORDERS = "LIST_ORDERS";
export const LIST_ORDERS_LOADING = "LIST_ORDERS_LOADING";
export const LIST_ORDERS_ERROR = "LIST_ORDERS_ERROR";
export const SELECT_ORDER = "SELECT_ORDER";
export const GET_ORDER_STATUSES = "GET_ORDER_STATUSES";
export const SET_FILTERS = "SET_FILTERS";

/// Companies
export const LOAD_COMPANY = "LOAD_COMPANY";
export const CHANGE_COMPANY_TAB = "CHANGE_COMPANY_TAB";

//Category
export const COMPANY_CATEGORIES_GET = "COMPANY_CATEGORIES_GET";
export const COMPANY_CATEGORIES_ADD = "COMPANY_CATEGORIES_ADD";
export const COMPANY_CATEGORIES_DELETE = "COMPANY_CATEGORIES_DELETE";

//positions
export const COMPANY_POSITIONS_LOADING = "COMPANY_POSITIONS_LOADING";
export const COMPANY_POSITIONS_ERROR = "COMPANY_POSITIONS_ERROR";
export const COMPANY_POSITIONS_GET = "COMPANY_POSITIONS_GET";
export const COMPANY_POSITIONS_ADD = "COMPANY_POSITIONS_ADD";
export const COMPANY_POSITIONS_DELETE = "COMPANY_POSITIONS_DELETE";
export const COMPANY_POSITIONS_UPDATE = "COMPANY_POSITIONS_UPDATE";

//task type
export const COMPANY_TASK_TYPE_LOADING = "COMPANY_TASK_TYPE_LOADING";
export const COMPANY_TASK_TYPE_ERROR = "COMPANY_TASK_TYPE_ERROR";
export const COMPANY_TASK_TYPE_GET = "COMPANY_TASK_TYPE_GET";
export const COMPANY_TASK_TYPE_ADD = "COMPANY_TASK_TYPE_ADD";
export const COMPANY_TASK_TYPE_DELETE = "COMPANY_TASK_TYPE_DELETE";
export const COMPANY_TASK_TYPE_UPDATE = "COMPANY_TASK_TYPE_UPDATE";

//people
export const COMPANY_PEOPLE_LOADING = "COMPANY_PEOPLE_LOADING";
export const COMPANY_PEOPLE_ERROR = "COMPANY_PEOPLE_ERROR";
export const COMPANY_PEOPLE_GET = "COMPANY_PEOPLE_GET";
export const COMPANY_PEOPLE_DELETE = "COMPANY_PEOPLE_DELETE";
export const COMPANY_PEOPLE_UPDATE = "COMPANY_PEOPLE_UPDATE";

//projects
export const COMPANY_PROJECTS_LOADING = "COMPANY_PROJECTS_LOADING";
export const COMPANY_PROJECTS_ERROR = "COMPANY_PROJECTS_ERROR";
export const COMPANY_PROJECTS_GET = "COMPANY_PROJECTS_GET";
export const COMPANY_PROJECTS_DELETE = "COMPANY_PROJECTS_DELETE";
export const COMPANY_PROJECTS_UPDATE = "COMPANY_PROJECTS_UPDATE";
export const COMPANY_PROJECTS_ADD = "COMPANY_PROJECTS_ADD";
export const SELECT_PROJECT = "SELECT_PROJECT";

//Livestream
export const LIVESTREAM_LOADING = "LIVESTREAM_LOADING";
export const LIVESTREAM_ERROR = "LIVESTREAM_ERROR";
export const CREATE_LIVESTREAM = "CREATE_LIVESTREAM";

//MemberTypes
export const GET_MEMBER_TYPES = "GET_MEMBER_TYPES";
export const GET_MEMBER_TYPES_LOADING = "GET_MEMBER_TYPES_LOADING";
export const GET_MEMBER_TYPES_ERROR = "GET_MEMBER_TYPES_ERROR";

//Tab Types
export const CREATE_PARAM = "CREATE_PARAM";
export const CREATE_TAB_TYPE = "CREATE_TAB_TYPE";
export const GET_TAB_TYPES = "GET_TAB_TYPES";
export const GET_TAB_TYPES_LOADING = "GET_TAB_TYPES_LOADING";
export const GET_TAB_TYPES_ERROR = "GET_TAB_TYPES_ERROR";

//Tab Data Types
export const GET_TAB_DATA_TYPES = "GET_TAB_DATA_TYPES";
export const GET_TAB_DATA_TYPES_LOADING = "GET_TAB_DATA_TYPES_LOADING";
export const GET_TAB_DATA_TYPES_ERROR = "GET_TAB_DATA_TYPES_ERROR";

//chat widget open state
export const TOGGLE_CHATS_WIDGET = "TOGGLE_CHATS_WIDGET";
export const CHANGE_EVENT = "CHANGE_EVENT";
export const CHANGE_TASK = "CHANGE_TASK";

export const LIST_NOTIFICATIONS_NO_ACTION_LOADING =
  "LIST_NOTIFICATIONS_NO_ACTION_LOADING";
export const LIST_NOTIFICATIONS_NO_ACTION_ERROR =
  "LIST_NOTIFICATIONS_NO_ACTION_ERROR";
export const LIST_NOTIFICATIONS_NO_ACTION = "LIST_NOTIFICATIONS_NO_ACTION";

export const LIST_NOTIFICATIONS_ACTION_LOADING =
  "LIST_NOTIFICATIONS_ACTION_LOADING";
export const LIST_NOTIFICATIONS_ACTION_ERROR =
  "LIST_NOTIFICATIONS_ACTION_ERROR";
export const LIST_NOTIFICATIONS_ACTION = "LIST_NOTIFICATIONS_ACTION";

export const TASK_ESTIMATION_LOADING = "TASK_ESTIMATION_LOADING";
export const TASK_ESTIMATION_ERROR = "TASK_ESTIMATION_ERROR";
export const TASK_ESTIMATION = "TASK_ESTIMATION";

//search
export const SEARCH_LOADING = "SEARCH_LOADING";
export const SEARCH_ERROR = "SEARCH_ERROR";
export const GET_SEARCH = "GET_SEARCH";
export const CHANGE_SEARCH_TYPE = "CHANGE_SEARCH_TYPE";
export const ADD_SEARCH_EXTRA_TYPE = "ADD_SEARCH_EXTRA_TYPE";
export const REMOVE_SEARCH_EXTRA_TYPES = "REMOVE_SEARCH_EXTRA_TYPES";
export const OPEN_SEARCH_ITEM = "OPEN_SEARCH_ITEM";
export const CLOSE_SEARCH_ITEM = "CLOSE_SEARCH_ITEM";

//Home
export const HOME_LOADING = "HOME_LOADING";
export const HOME_ERROR = "HOME_ERROR";
export const GET_HOME = "GET_HOME";
export const INSERT_HOME_DATA = "INSERT_HOME_DATA";
export const DELETE_HOME_DATA = "DELETE_HOME_DATA";

// calendar
export const CALENDAR_LOADING = "CALENDAR_LOADING";
export const LOAD_CALENDAR = "LOAD_CALENDAR";
export const LOAD_CALENDAR_ERROR = "LOAD_CALENDAR_ERROR";

// stage
export const STAGE_LOADING = "STAGE_LOADING";
export const LOAD_STAGE = "LOAD_STAGE";
export const LOAD_STAGE_ERROR = "LOAD_STAGE_ERROR";
export const ADD_STAGE = "ADD_STAGE";
export const EDIT_STAGE = "EDIT_STAGE";
export const DELETE_STAGE = "DELETE_STAGE";
export const WITH_SESSIONS_LOADING = "WITH_SESSIONS_LOADING";
export const LOAD_WITH_SESSIONS = "LOAD_WITH_SESSIONS";
export const WITH_SESSIONS_ERROR = "WITH_SESSIONS_ERROR";

export const CREATE_SESSION_LOADING = "CREATE_SESSION_LOADING";
export const CREATE_SESSION_ERROR = "CREATE_SESSION_ERROR";
export const UPDATE_SESSION = "UPDATE_SESSION";

export const TYPE_STAGE_LOADING = "TYPE_STAGE_LOADING";
export const LOAD_TYPE_STAGE = "LOAD_TYPE_STAGE";
export const LOAD_TYPE_STAGE_ERROR = "LOAD_TYPE_STAGE_ERROR";
// hall
export const HALL_LOADING = "HALL_LOADING";
export const LOAD_HALL = "LOAD_HALL";
export const LOAD_HALL_ERROR = "LOAD_HALL_ERROR";
export const ADD_HALL = "ADD_HALL";
export const EDIT_HALL = "EDIT_HALL";
export const DELETE_HALL = "DELETE_HALL";
export const TYPE_HALL_LOADING = "TYPE_HALL_LOADING";
export const LOAD_TYPE_HALL = "LOAD_TYPE_HALL";
export const LOAD_TYPE_HALL_ERROR = "LOAD_TYPE_HALL_ERROR";

export const ADD_SPEAKER_EVENT = "ADD_SPEAKER_EVENT";

export const TEAM_LOADING = "TEAM_LOADING";
export const LOAD_TEAM = "LOAD_TEAM";
export const LOAD_TEAM_ERROR = "LOAD_TEAM_ERROR";
export const ADD_TEAM = "ADD_TEAM";
export const EDIT_TEAM = "EDIT_TEAM";
export const DELETE_TEAM = "DELETE_TEAM";

export const ORGANIZATION_EVENT_LOADING = "ORGANIZATION_EVENT_LOADING";
export const LOAD_ORGANIZATION_EVENT = "LOAD_ORGANIZATION_EVENT";
export const LOAD_ORGANIZATION_EVENT_ERROR = "LOAD_ORGANIZATION_EVENT_ERROR";
export const ADD_ORGANIZATION_EVENT = "ADD_ORGANIZATION_EVENT";
export const EDIT_ORGANIZATION_EVENT = "EDIT_ORGANIZATION_EVENT";
export const DELETE_ORGANIZATION_EVENT = "DELETE_ORGANIZATION_EVENT";

export const LOAD_SPEAKER_ERROR = "LOAD_SPEAKER_ERROR";
export const SPEAKER_LOADING = "SPEAKER_LOADING";
export const LOAD_SPEAKER = "LOAD_SPEAKER";
export const DELETE_SPEAKER = "DELETE_SPEAKER";

export const ORDER_ERROR = "ORDER_ERROR";
export const ADD_ORDER = "ADD_ORDER";
export const ORDER = "ORDER";

export const ADD_BUILDING = "ADD_BUILDING";
export const DELETE_BUILDING = "DELETE_BUILDING";
export const EDIT_BUILDING = "EDIT_BUILDING";
export const BUILDING_LOADING = "BUILDING_LOADING";
export const LOAD_BUILDING = "LOAD_BUILDING";
export const LOAD_BUILDING_ERROR = "LOAD_BUILDING_ERROR";

export const EVENTS_TYPE_LOADING = "EVENTS_TYPE_LOADING";
export const LOAD_EVENTS_TYPE_ERROR = "LOAD_EVENTS_TYPE_ERROR";
export const LOAD_EVENTS_TYPE = "LOAD_EVENTS_TYPE";

export const SESSION_LOADING = "SESSION_LOADING";
export const LOAD_SESSION_ERROR = "LOAD_SESSION_ERROR";
export const LOAD_SESSION = "LOAD_SESSION";

export const LIMIT_PAGE_LOADING = "LIMIT_PAGE_LOADING";
export const LOAD_LIMIT_PAGE = "LOAD_LIMIT_PAGE";
export const LOAD_LIMIT_PAGE_ERROR = "LOAD_LIMIT_PAGE_ERROR";
export const ADD_LIMIT_PAGE = "ADD_LIMIT_PAGE";
export const EDIT_LIMIT_PAGE = "EDIT_LIMIT_PAGE";

export const SERVICES_LOADING = "SERVICES_LOADING";
export const LOAD_SERVICES = "LOAD_SERVICES";
export const LOAD_SERVICES_ERROR = "LOAD_SERVICES_ERROR";
export const ADD_SERVICES = "ADD_SERVICES";
export const EDIT_SERVICES = "EDIT_SERVICES";
export const DELETE_SERVICES = "DELETE_SERVICES";
export const SERVICES_CATEGORY_LOADING = "SERVICES_CATEGORY_LOADING";
export const LOAD_SERVICES_CATEGORY_ERROR = "LOAD_SERVICES_CATEGORY_ERROR";
export const LOAD_SERVICES_CATEGORY = "LOAD_SERVICES_CATEGORY";
export const ADD_SERVICES_CATEGORY = "ADD_SERVICES_CATEGORY";

export const INDUSTRY_LOADING = "INDUSTRY_LOADING";
export const LOAD_INDUSTRY_ERROR = "LOAD_INDUSTRY_ERROR";
export const LOAD_INDUSTRY = "LOAD_INDUSTRY";

export const SET_CURRENT_ORGANIZATION_ORDER = "SET_CURRENT_ORGANIZATION_ORDER";
