import {
  LIST_PRODUCTS_LOADING,
  LIST_PRODUCTS_ERROR,
  LIST_PRODUCTS,
  DELETE_PRODUCT,
  UPDATE_PRODUCT,
  CREATE_PRODUCT,
} from "./../actions/types";
import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  error: "",
  loading: false,
  data: [],
  thisPage: 0,
  nextPage: 0,
  hasMore: true,
  count: 0,
};

const ordersReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(LIST_PRODUCTS_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(LIST_PRODUCTS_ERROR, (state, { payload }) => {
      state.error = payload;
    })
    .addCase(LIST_PRODUCTS, (state, { payload }) => {
      state.data =
        payload?.thisPage === 1
          ? payload?.data
          : [...state?.data, ...payload?.data];
      state.thisPage = payload.thisPage;
      state.nextPage = payload.nextPage;
      state.hasMore = payload.hasMore;
      state.count = payload.count;
      state.loading = false;
    })
    .addCase(DELETE_PRODUCT, (state, { payload }) => {
      state.data = state.data.filter((e) => e.id !== payload);
    })
    .addCase(CREATE_PRODUCT, (state, { payload }) => {
      state.data.unshift(payload);
      state.loading = false;
      state.error = false;
    })
    .addCase(UPDATE_PRODUCT, (state, { payload }) => {
      const foundProduct = state.data.filter((e) => e.id === payload?.id)[0];
      const index = state.data.indexOf(foundProduct);
      state.data[index] = { ...state.data[index], ...payload };
      state.loading = false;
      state.error = false;
    });
});

export default ordersReducer;
