import { getRequest, postRequest } from "../../../connection/network";
import { socket } from "../../../connection/socket";
import { updateProfileService } from "../../../services/auth";
import { fetchStickerPacks } from "../../../services/stickers";
import { getChatsAction } from "../chat";
import {
  AUTH_ERROR,
  USER_LOADED,
  LOGIN_SUCCESS,
  UPDATE_UN_READ,
  UPDATE_PROFILE,
  LOGOUT_SUCCESS,
} from "../types";
const authApis = {
  me: "auth/me",
  login: "auth/login",
};
export const logoutAction = () => (dispatch) => {
  socket.distroySocket();
  dispatch({ type: LOGOUT_SUCCESS });
};

export const getUser = async (dispatch) => {
  try {
    const token = localStorage.getItem("token");

    if (!token) return dispatch({ type: AUTH_ERROR });

    let deviceToken = localStorage?.getItem("firebaseToken");

    const res = await getRequest({
      token,
      endPoint: authApis.me,
      query: { deviceToken },
    });

    const { user } = res.data.data;

    socket.connect(token, parseInt(user.id), dispatch);

    getChatsAction({ page: 1, limit: 45, type: "all", firstTime: true })(
      dispatch
    );

    dispatch({
      type: USER_LOADED,
      payload: {
        token,
        user,
      },
    });

    fetchStickerPacks();

    dispatch({
      type: UPDATE_UN_READ,
      payload: { unReadNotificationsCount: user.unReadNotificationsCount },
    });

    return { token };
  } catch (error) {
    dispatch({ type: AUTH_ERROR });
    return { token: null };
  }
};

export const login =
  ({ mobile, password, pageId, companyId }) =>
  (dispatch) => {
    let deviceToken = localStorage.getItem("firebaseToken");
    return postRequest({
      body: {
        mobile,
        password,
        deviceToken,
        pageId: pageId || undefined,
        companyId: companyId || undefined,
      },
      endPoint: authApis.login,
    })
      .catch((error) => {
        dispatch({
          type: AUTH_ERROR,
          payload: "Invalid mobile, email or password",
        });
      })
      .then((res) => {
        localStorage.setItem("token", res.data.data.token);
        const {
          data: { user, token },
        } = res.data;
        socket.connect(token, parseInt(user.id), dispatch);
        getChatsAction({ page: 1, limit: 45, type: "all", firstTime: true })(
          dispatch
        );
        fetchStickerPacks();
        dispatch({
          type: UPDATE_UN_READ,
          payload: { unReadNotificationsCount: user.unReadNotificationsCount },
        });
        dispatch({
          type: LOGIN_SUCCESS,
          payload: {
            token,
            user,
          },
        });
      });
  };

export const updateProfile =
  ({ data, cb }) =>
  (dispatch) => {
    return updateProfileService({ body: data, cb }).then((res) => {
      const { error, data } = res;
      if (error) {
        if (cb) cb(error);
        return;
      }
      dispatch({ type: UPDATE_PROFILE, payload: data });
      cb && cb(null);
    });
  };
