import {
  postRequest,
  getRequest,
  delRequest,
  putRequest,
} from "../connection/network";
import store from "../store";
const tasksApi = {
  list: "task",
  assignees: "task/assignees",
  status: "task/status",
  estimation: "/estimation",
  taskStatus: "/taskStatus",
};

export const listTasksService = async ({
  page,
  limit,
  text,
  status,
  type,
  chatId,
  pageId,
  pageCategoryId,
  taskId,
  endDate,
  startDate,
  taskTypeId,
}) => {
  try {
    const token = localStorage.getItem("token");
    const {
      data: {
        data,
        meta: { thisPage, allPages },
      },
    } = await getRequest({
      token,
      query: {
        page,
        limit,
        text,
        status,
        type,
        chatId,
        pageId,
        pageCategoryId,
        taskId,
        endDate,
        startDate,
        taskTypeId,
      },
      endPoint: tasksApi.list,
    });

    return {
      data,
      thisPage: parseInt(thisPage),
      allPages: parseInt(allPages),
    };
  } catch (error) {
    const sError =
      error?.response?.data?.error?.message || "Unknown error occured";
    return { error: sError };
  }
};
export const listTaskAssigneesService = async ({
  page,
  limit,
  text,
  taskId,
}) => {
  try {
    const token = localStorage.getItem("token");
    const {
      data: {
        data,
        meta: { thisPage, allPages },
      },
    } = await getRequest({
      token,
      query: {
        page,
        limit,
        text,
        taskId,
      },
      endPoint: tasksApi.assignees,
    });

    return {
      data,
      thisPage: parseInt(thisPage),
      allPages: parseInt(allPages),
    };
  } catch (error) {
    const sError =
      error?.response?.data?.error?.message || "Unknown error occured";
    return { error: sError };
  }
};

export const createNewTaskService = async ({ data: sendData }) => {
  try {
    const token = localStorage.getItem("token");
    let {
      data: { data, message },
    } = await postRequest({ body: sendData, endPoint: tasksApi.list, token });
    data = { ...data.task, ...data };
    delete data.task;
    data.creator = store.getState()?.auth?.user;
    return {
      data,
      message,
    };
  } catch (error) {
    const sError =
      error?.response?.data?.error?.message || "Unknown error occured";
    return { error: sError };
  }
};

export const deleteTaskService = async ({ taskIds }) => {
  try {
    const token = localStorage.getItem("token");
    const {
      data: { data, message },
    } = await delRequest({
      endPoint: tasksApi.list,
      token,
      query: {
        taskIds,
      },
    });
    return {
      data,
      message,
    };
  } catch (error) {
    const sError =
      error?.response?.data?.error?.message || "Unkown error occured";
    return { error: sError };
  }
};
export const updateTaskAssigneesService = async ({
  taskId,
  removingUserIds = [],
  addingUserIds = [],
}) => {
  try {
    const token = localStorage.getItem("token");
    const {
      data: { data, message },
    } = await putRequest({
      endPoint: tasksApi.assignees,
      token,
      body: {
        removingUserIds,
        taskId,
        addingUserIds,
      },
    });
    return {
      data,
      message,
    };
  } catch (error) {
    const sError =
      error?.response?.data?.error?.message || "Unkown error occured";
    return { error: sError };
  }
};
export const editTaskService = async ({ sendData }) => {
  try {
    const token = localStorage.getItem("token");
    let {
      data: { data, message },
    } = await putRequest({ body: sendData, endPoint: tasksApi.list, token });
    data.creator = store.getState()?.auth?.user;
    return {
      data,
      message,
    };
  } catch (error) {
    const sError =
      error?.response?.data?.error?.message || "Unkown error occured";
    return { error: sError };
  }
};

export const updateTaskStatusService = async ({ taskId, status, progress }) => {
  try {
    const token = localStorage.getItem("token");
    const {
      data: { data, message },
    } = await putRequest({
      endPoint: tasksApi.status,
      token,
      body: {
        taskId,
        status,
        progress,
      },
    });
    return {
      data,
      message,
    };
  } catch (error) {
    const sError =
      error?.response?.data?.error?.message || "Unkown error occured";
    return { error: sError };
  }
};

export const updateEstimationService = async ({
  taskId,
  actualTimeHr,
  actualTimeMin,
  estimationHr,
  estimationMin,
}) => {
  try {
    const token = localStorage.getItem("token");
    const {
      data: { data, message },
    } = await putRequest({
      endPoint: tasksApi.estimation,
      token,
      body: {
        taskId,
        actualTimeHr,
        actualTimeMin,
        estimationHr,
        estimationMin,
      },
    });
    return {
      data,
      message,
    };
  } catch (error) {
    const sError =
      error?.response?.data?.error?.message || "Unkown error occured";
    return { error: sError };
  }
};

export const updateStatusService = async ({ taskId, status }) => {
  try {
    const token = localStorage.getItem("token");
    const {
      data: { data, message },
    } = await putRequest({
      endPoint: tasksApi.taskStatus,
      token,
      body: {
        taskId,
        status,
      },
    });
    return {
      data,
      message,
    };
  } catch (error) {
    const sError =
      error?.response?.data?.error?.message || "Unkown error occured";
    return { error: sError };
  }
};
