import { createReducer } from "@reduxjs/toolkit";
import {
  CREATE_LIVESTREAM,
  LIVESTREAM_ERROR,
  LIVESTREAM_LOADING,
} from "../actions/types";

const initials = {
  data: [],
  thisPage: 0,
  allPages: 0,
  loading: false,
  error: false,
};

const livestreamReducer = createReducer(initials, (builder) => {
  builder
    .addCase(LIVESTREAM_ERROR, (s, { payload }) => {
      s.error = payload;
      s.loading = false;
    })
    .addCase(LIVESTREAM_LOADING, (s) => {
      s.loading = true;
    })
    .addCase(CREATE_LIVESTREAM, (s, { payload }) => {
      s.data.unshift(payload);
      s.loading = false;
      s.error = false;
    });
});

export default livestreamReducer