import { createReducer } from "@reduxjs/toolkit";
import { CHOSE_ADDRESS, CHANGE_NOTES } from "../actions/types";

const initialState = {
  error: "",
  loading: false,
  data: {},
  address: {},
  paymentData: {},
  notes: "",
};

const paymentReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(CHOSE_ADDRESS, (state, { payload }) => {
      if (payload.chosenAddress) {
        state.address = payload.chosenAddress;
      }
      if (payload.paymentData) {
        state.paymentData = payload.paymentData;
      }
      state.data = { ...state.data, ...payload?.payment };
    })
    .addCase(CHANGE_NOTES, (state, { payload }) => {
      state["notes"] = payload;
    });
});

export default paymentReducer;
