import {
  SINGLE_CHAT_ERROR,
  SINGLE_CHAT_LOADED,
  SINGLE_CHAT_LOADING,
  INSERT_NEW_MESSAGE,
  CONVERT_MESSAGE,
  MESSAGE_SENT,
  UPDATE_CHAT_INFO,
  DELETE_CHAT,
  EMPTY_SINGLE_CHAT,
  SET_MESSAGE_DELETED,
  REMOVE_MESSAGES,
  CLEAR_CHAT_MESSAGES,
  STARR_MESSAGE_CHANGE,
  CHANGE_MESSAGE_REACT,
  ADD_CHAT_MEMBERS,
  DELETE_CHAT_MEMBER,
  CHOOSE_MESSAGE_POLL_OPTION,
} from "../actions/types";
import { createReducer } from "@reduxjs/toolkit";
import { changeLOMessage } from "../../storage/chats";

const initialState = {
  error: "",
  loading: false,
  chat: null,
  messages: {
    data: [],
    count: 0,
    allPages: 0,
  },
  hasMore: true,
};
const singleChatReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(DELETE_CHAT_MEMBER, (s, { payload }) => {
      const { chatId, userId } = payload;
      if (chatId == s.chat?.id) {
        s.chat?.users?.filter((user) => user?.id !== userId);
      }
    })
    .addCase(ADD_CHAT_MEMBERS, (s, { payload = {} }) => {
      const { chatId, newUsers } = payload;
      if (chatId == s.chat?.id) {
        s.chat.users = [...(s.chat?.users || []), ...newUsers];
      }
    })
    .addCase(CHANGE_MESSAGE_REACT, (state, { payload }) => {
      const {
        uuid,
        chatId,
        deleteReact,
        reactionTypeId,
        meReacted,
        changed,
        userReacts,
      } = payload;

      if (chatId === state.chat?.id) {
        let mIndex = state.messages.data?.findIndex((s) => s.uuid === uuid);

        if (mIndex > -1) {
          let message = state.messages.data[mIndex];
          const userReaction = message["userReaction"];
          if (!message["reacts"]) {
            message["reacts"] = {
              1: 0,
              2: 0,
              3: 0,
              4: 0,
              5: 0,
              6: 0,
              7: 0,
              8: 0,
              9: 0,
              total: 0,
            };
          }
          if (changed) {
            message["reacts"]["total"]--;
            message["totalReactionCount"]--;
            message["reacts"][userReacts]--;
          }

          if (deleteReact) {
            if (meReacted) message["userReaction"] = false;
            message["totalReactionCount"]--;
            message["reacts"]["total"]--;
            // if (meReacted) message["reacts"][userReaction]--;
          } else {
            if (meReacted) {
              message["userReaction"] = reactionTypeId;
              if (userReaction) {
                message["userReaction"] = reactionTypeId;
                // message["reacts"][userReaction]--;
              }
            }
            message["reacts"]["total"]++;
            message["totalReactionCount"]++;
            message["reacts"][reactionTypeId]++;
          }

          changeLOMessage({ uuids: [uuid], chatId, newToUpdate: message });
          state.messages.data[mIndex] = {
            ...state.messages.data[mIndex],
            ...message,
          };
        }
      }
    })
    .addCase(STARR_MESSAGE_CHANGE, (state, { payload }) => {
      const { chatId, uuid = [], starred } = payload;
      if (chatId === state.chat?.id) {
        state.messages.data = state.messages.data.map((message) => {
          let isIncluded = uuid.includes(message?.uuid);
          if (isIncluded) message.starred = starred;
          return message;
        });
      }
    })
    .addCase(CLEAR_CHAT_MESSAGES, (state, { payload }) => {
      state.messages = {
        data: [],
        allPages: 0,
        thisPage: 0,
      };
    })
    .addCase(REMOVE_MESSAGES, (state, { payload }) => {
      if (payload?.chatId === state?.chat?.id) {
        state.messages.data = state.messages.data?.filter(
          (message) => !payload?.uuid?.includes(message?.uuid)
        );
      }
    })
    .addCase(SET_MESSAGE_DELETED, (state, { payload }) => {
      const { chatId, uuid = [] } = payload;
      if (chatId === state.chat?.id) {
        state.messages.data = state.messages.data.map((message) => {
          let isIncluded = uuid.includes(message?.uuid);
          if (isIncluded) message.inChatDeletedAt = String(new Date());
          return message;
        });
      }
    })
    .addCase(EMPTY_SINGLE_CHAT, (s) => {
      s = initialState;
    })
    .addCase(DELETE_CHAT, (state, { payload }) => {
      if (state.chat?.id === payload) {
        state.chat = null;
        state.messages = { data: [], allPages: 0, thisPage: 0 };
      }
    })
    .addCase(UPDATE_CHAT_INFO, (state, { payload }) => {
      if (state.chat?.id == payload?.id) {
        state.chat = { ...state.chat, ...payload };
      }
    })
    .addCase(INSERT_NEW_MESSAGE, (state, { payload }) => {
      state.messages.data.push({ ...payload });
    })
    .addCase(SINGLE_CHAT_ERROR, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    })
    .addCase(SINGLE_CHAT_LOADING, (state, { payload }) => {
      let knownChat = payload?.knownChat;

      state.loading = true;
      state.error = false;
      if (knownChat) state.chat = knownChat;
    })
    .addCase(SINGLE_CHAT_LOADED, (state, { payload }) => {
      state.error = false;
      state.chat = payload.chat;
      state.otherChats = payload.otherChats;
      state.messages = {
        thisPage: payload.messages.thisPage,
        count: payload.messages.count,
        data:
          payload.loadMore && payload.messages.thisPage !== 1
            ? [...payload.messages.data, ...state?.messages.data]
            : [...payload.messages.data],
      };
      state.hasMore = payload.hasMore;
      state.loading = false;
    })
    .addCase(CONVERT_MESSAGE, (state, { payload }) => {
      const mIndex = state.messages.data.findIndex(
        (c) =>
          (c.id && c.id === payload?.messageId) ||
          (c.uuid && c.uuid === payload?.uuid) ||
          (c.feMark && parseInt(c.feMark) === parseInt(payload?.feMark))
      );
      if (mIndex > -1) {
        state.messages.data[mIndex]["type"] = { ...payload };
      }
    })
    .addCase(MESSAGE_SENT, (state, { payload }) => {
      if (payload.newChat) {
        if (payload.chatId !== state?.chat?.id) {
          state.chat.id = payload.chatId;
        }
      }
      let mIndex = state.messages.data.findIndex(
        (c) => c.feMark === parseInt(payload?.feMark)
      );

      if (mIndex > -1) {
        let message = state.messages.data[mIndex];
        state.messages.data[mIndex] = {
          ...message,
          ...payload,
          status: "sent",
        };
      } else {
        if (parseInt(payload.chatId) === state.chat.id)
          state?.messages.data.push({ ...payload });
      }
    })
    .addCase(CHOOSE_MESSAGE_POLL_OPTION, (state, { payload }) => {
      const index = state.messages.data.findIndex(
        (e) => e?.id === payload?.messageId
      );
      state.messages.data[index].poll = payload?.poll;
    });
});

export default singleChatReducer;
