import {
  TASKS_LOADING,
  LOAD_TASKS,
  LOAD_TASKS_ERROR,
  NEW_TASK_LOADING,
  NEW_TASK_ERROR,
  CREATE_NEW_TASK,
  GET_SINGLE_TASK,
  GET_SINGLE_TASK_LOADING,
  GET_SINGLE_TASK_ERROR,
  CHANGE_TASK_STATUS,
  DELETE_TASK,
  GET_TASK_ASSIGNEES,
  GET_TASK_ASSIGNEES_ERROR,
  GET_TASK_ASSIGNEES_LOADING,
  UPDATE_TASK_ASSIGNEES,
  GET_DEPENDENT_TASKS_LOADING,
  GET_DEPENDENT_TASKS_ERROR,
  GET_DEPENDENT_TASKS,
  TASK_ESTIMATION_LOADING,
  TASK_ESTIMATION_ERROR,
  TASK_ESTIMATION,
} from "../actions/types";
import { createReducer } from "@reduxjs/toolkit";
let assigneesInitial = {
  loading: false,
  error: false,
  data: [],
  thisPage: 0,
  allPages: 0,
  count: 0,
};

let singleTaskInitial = {
  loading: false,
  error: false,
  data: null,
  assignees: assigneesInitial,
};

let addTaskInitial = {
  loading: false,
  error: false,
  data: [],
  thisPage: 0,
  allPages: 0,
};

let estimationInitial = {
  loading: false,
  error: false,
  data: {},
};

const initials = {
  data: [],
  thisPage: 0,
  allPages: 0,
  error: false,
  loading: false,
  newLoading: false,
  newError: false,
  singleTask: singleTaskInitial,
  addTask: addTaskInitial,
  estimation: estimationInitial,
};

const taskReducer = createReducer(initials, (builder) => {
  builder
    .addCase(UPDATE_TASK_ASSIGNEES, (s, { payload = {} }) => {
      const { taskId, removingUserIds = [], addingUsers } = payload;
      if (taskId === s.singleTask.data.id && s.singleTask.assignees) {
        s.singleTask.assignees.data = [
          ...addingUsers,
          ...(s.singleTask.assignees.data || []),
        ];
        s.singleTask.assignees.data = s.singleTask.assignees.data.filter(
          (assignee) => !removingUserIds.includes(assignee.id)
        );
      }
    })
    .addCase(DELETE_TASK, (s, { payload }) => {
      if (s.singleTask?.data?.id === payload) {
        s.singleTask = singleTaskInitial;
      }
      s.data = s.data.filter((task) => task.id !== payload);
    })
    .addCase(CHANGE_TASK_STATUS, (s, { payload = {} }) => {
      const { taskId, status, progress } = payload;

      if (s.singleTask?.data?.id == taskId) {
        if (status && s.singleTask.data.status)
          s.singleTask.data.status.status = status;
        if (progress && s.singleTask.data.status)
          s.singleTask.data.status.progress = progress;
        let isFound = s.data?.findIndex((t) => t?.id === taskId);
        if (isFound > -1) {
          let task = s.data[isFound];

          if (task.status && status) s.data[isFound].status.status = status;
          if (task.status && progress)
            s.data[isFound].status.progress = progress;
        }
      }
    })
    .addCase(GET_SINGLE_TASK_ERROR, (s, { payload }) => {
      s.singleTask.error = payload;
      s.singleTask.data = null;
      s.singleTask.loading = false;
    })
    .addCase(GET_SINGLE_TASK_LOADING, (s) => {
      // s.singleTask = singleTaskInitial;
      s.singleTask.loading = true;
    })
    .addCase(GET_SINGLE_TASK, (s, { payload }) => {
      s.singleTask.data = payload;
      s.singleTask.loading = false;
      s.singleTask.error = "";
    })

    .addCase(TASK_ESTIMATION_LOADING, (s) => {
      s.estimation.loading = true;
      s.estimation = estimationInitial;
    })
    .addCase(TASK_ESTIMATION_ERROR, (s, { payload }) => {
      s.estimation.error = payload;
      s.estimation.data = null;
      s.estimation.loading = false;
    })

    .addCase(TASK_ESTIMATION, (s, { payload }) => {
      s.estimation.data = payload;
      s.estimation.loading = false;
      s.estimation.error = "";
    })

    .addCase(GET_DEPENDENT_TASKS_ERROR, (s, { payload }) => {
      s.addTask.error = payload;
      s.addTask.data = null;
      s.addTask.loading = false;
    })
    .addCase(GET_DEPENDENT_TASKS_LOADING, (s) => {
      s.addTask.loading = true;
    })
    .addCase(GET_DEPENDENT_TASKS, (s, { payload }) => {
      // s.addTask.data = payload;
      s.addTask.data =
        payload?.thisPage === 1
          ? payload?.data
          : [...s?.addTask?.data, ...payload?.data];
      s.addTask.count = payload.count;

      s.addTask.allPages = payload.allPages;
      s.addTask.thisPage = payload.thisPage;

      s.addTask.loading = false;
      s.addTask.error = "";
    })
    .addCase(CREATE_NEW_TASK, (s, { payload }) => {
      s.data.unshift(payload);
      s.newLoading = false;
      s.newError = false;
    })
    .addCase(NEW_TASK_ERROR, (s, { payload }) => {
      s.newError = payload || true;
      s.newLoading = false;
    })
    .addCase(NEW_TASK_LOADING, (s) => {
      s.newLoading = true;
      s.newError = false;
    })
    .addCase(TASKS_LOADING, (s) => {
      s.loading = true;
    })
    .addCase(LOAD_TASKS_ERROR, (s, { payload }) => {
      s.error = payload;
    })
    .addCase(LOAD_TASKS, (s, { payload }) => {
      s.data =
        payload?.thisPage === 1
          ? payload?.data
          : [...s?.data, ...payload?.data];
      s.count = payload.count;
      s.allPages = payload.allPages;
      s.thisPage = payload.thisPage;
      s.loading = false;
    })
    .addCase(GET_TASK_ASSIGNEES_LOADING, (s, { payload }) => {
      s.singleTask.assignees.loading = true;
    })
    .addCase(GET_TASK_ASSIGNEES_ERROR, (s, { payload }) => {
      s.singleTask.assignees.error = payload;
    })
    .addCase(GET_TASK_ASSIGNEES, (s, { payload }) => {
      s.singleTask.assignees.data =
        payload?.thisPage === 1
          ? payload?.data
          : [...s?.singleTask.assignees.data, ...payload?.data];
      s.singleTask.assignees.count = payload.count;
      s.singleTask.assignees.allPages = payload.allPages;
      s.singleTask.assignees.thisPage = payload.thisPage;
      s.singleTask.assignees.loading = false;
    });
});

export default taskReducer;
