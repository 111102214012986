import {
  LIST_NOTIFICATIONS,
  LIST_NOTIFICATIONS_ERROR,
  LIST_NOTIFICATIONS_LOADING,
  INSERT_NEW_NOTIFICATION,
  UPDATE_UN_READ,
  CHANGE_EVENT,
  CHANGE_TASK,
  LIST_NOTIFICATIONS_NO_ACTION_ERROR,
  LIST_NOTIFICATIONS_ACTION_ERROR,
  LIST_NOTIFICATIONS_NO_ACTION_LOADING,
  LIST_NOTIFICATIONS_ACTION_LOADING,
  LIST_NOTIFICATIONS_NO_ACTION,
  LIST_NOTIFICATIONS_ACTION,
} from "../actions/types";
import { createReducer } from "@reduxjs/toolkit";

const actionInitials = {
  data: [],
  thisPage: 0,
  allPages: 0,
  error: false,
  totalUnRead: 0,
};
const noActionInitials = {
  data: [],
  thisPage: 0,
  allPages: 0,
  error: false,
  totalUnRead: 0,
};
const initials = {
  data: [],
  thisPage: 0,
  allPages: 0,
  error: false,
  totalUnRead: 0,
  noAction: noActionInitials,
  action: actionInitials,
};
const notifyReducer = createReducer(initials, (builder) => {
  builder
    .addCase(UPDATE_UN_READ, (s, { payload }) => {
      s.totalUnRead = payload.unReadNotificationsCount;
    })
    .addCase(INSERT_NEW_NOTIFICATION, (s, { payload }) => {
      s.totalUnRead++;
      s.data.unshift(payload);
    })
    .addCase(LIST_NOTIFICATIONS_LOADING, (s) => {
      s.loading = true;
    })
    .addCase(LIST_NOTIFICATIONS_ERROR, (s, { payload }) => {
      s.error = payload;
    })
    .addCase(LIST_NOTIFICATIONS_ACTION_LOADING, (s) => {
      s.action.loading = true;
    })
    .addCase(LIST_NOTIFICATIONS_ACTION_ERROR, (s, { payload }) => {
      s.action.error = payload;
    })
    .addCase(LIST_NOTIFICATIONS_NO_ACTION_LOADING, (s) => {
      s.noAction.loading = true;
    })
    .addCase(LIST_NOTIFICATIONS_NO_ACTION_ERROR, (s, { payload }) => {
      s.noAction.error = payload;
    })
    .addCase(LIST_NOTIFICATIONS_ACTION, (s, { payload }) => {
      s.action.data =
        payload?.thisPage === 1
          ? payload?.data
          : [...s?.action.data, ...payload?.data];
      s.action.count = payload.count;
      s.action.allPages = payload.allPages;
      s.action.thisPage = payload.thisPage;
      s.action.loading = false;
      s.action.totalUnRead = 0;
    })
    .addCase(LIST_NOTIFICATIONS_NO_ACTION, (s, { payload }) => {
      s.noAction.data =
        payload?.thisPage === 1
          ? payload?.data
          : [...s?.noAction.data, ...payload?.data];
      s.noAction.count = payload.count;
      s.noAction.allPages = payload.allPages;
      s.noAction.thisPage = payload.thisPage;
      s.noAction.loading = false;
      s.noAction.totalUnRead = 0;
    })
    .addCase(LIST_NOTIFICATIONS, (s, { payload }) => {
      s.data =
        payload?.thisPage === 1
          ? payload?.data
          : [...s?.data, ...payload?.data];
      s.count = payload.count;
      s.allPages = payload.allPages;
      s.thisPage = payload.thisPage;
      s.loading = false;
      s.totalUnRead = 0;
    })
    .addCase(CHANGE_EVENT, (s, { payload = {} }) => {
      const { eventId, status } = payload;
      if (s.singleEvent?.data?.entityId === eventId) {
        if (status && s.singleEvent.data?.event?.status)
          s.singleEvent.data.event.status.status = status;

        let isFound = s.data?.findIndex((t) => t?.entityId === eventId);
        if (isFound > -1) {
          let event = s.data[isFound];
          if (event?.event?.status && status)
            s.data[isFound].event.status.status = status;
        }
      }
    })
    .addCase(CHANGE_TASK, (s, { payload = {} }) => {
      const { taskId, status, progress } = payload;

      if (s.singleTask?.data?.entityId === taskId) {
        if (status && s.singleTask.data?.task?.status)
          s.singleTask.data.task.status.status = status;
        if (progress && s.singleTask.data?.task?.status)
          s.singleTask.data.task.status.progress = progress;
        let isFound = s.data?.findIndex((t) => t?.entityId === taskId);
        if (isFound > -1) {
          let task = s.data[isFound];

          if (task?.task?.status && status)
            s.data[isFound].task.status.status = status;
          if (task?.task?.status && progress)
            s.data[isFound].task.status.progress = progress;
        }
      }
    });
});

export default notifyReducer;
