import { Box } from "@mui/material";
import { logoIcon } from "../../Assets";

export const AppLoader = ({ loader }) => {
  return (
    <Box
      sx={{ height: "100vh" }}
      display="flex"
      alignItems="center"
      justifyContent="center"
      justifyItems="center"
    >
      {loader || <img src={logoIcon} alt="Shutter Studio" height={84} />}
    </Box>
  );
};
