import {
  postRequest,
  getRequest,
  putRequest,
  delRequest,
} from "../connection/network";
import store from "../store";
const eventsApi = {
  list: "event",
  join: "event/join",
  invitees: "event/invitees",
  status: "event/status",
  live: "event/live",
  type: "event/types",
};

export const createNewEventService = async ({ data: sendData }) => {
  try {
    const token = localStorage.getItem("token");
    let {
      data: { data, message },
    } = await postRequest({ body: sendData, endPoint: eventsApi.list, token });
    data = { ...data.event, ...data };
    delete data.event;
    data.creator = store.getState()?.auth?.user;
    return {
      data,
      message,
    };
  } catch (error) {
    const sError =
      error?.response?.data?.error?.message || "Unkown error occured";
    return { error: sError };
  }
};

export const editEventService = async ({ sendData }) => {
  try {
    const token = localStorage.getItem("token");
    let {
      data: { data, message },
    } = await putRequest({ body: sendData, endPoint: eventsApi.list, token });
    data = { ...data.event, ...data };
    delete data.event;
    data.creator = store.getState()?.auth?.user;
    return {
      data,
      message,
    };
  } catch (error) {
    const sError =
      error?.response?.data?.error?.message || "Unkown error occured";
    return { error: sError };
  }
};
export const joinEventService = async ({ eventId }) => {
  try {
    const token = localStorage.getItem("token");
    let {
      data: { data, message },
    } = await putRequest({
      body: { eventId },
      endPoint: eventsApi.join,
      token,
    });
    data = { ...data };
    return {
      data,
      message,
    };
  } catch (error) {
    const sError =
      error?.response?.data?.error?.message || "Unkown error occured";
    return { error: sError };
  }
};
export const listEventInviteesService = async ({
  page,
  limit,
  text,
  eventId,
}) => {
  try {
    const token = localStorage.getItem("token");
    const {
      data: {
        data,
        meta: { thisPage, allPages },
      },
    } = await getRequest({
      token,
      query: {
        page,
        limit,
        text,
        eventId,
      },
      endPoint: eventsApi.invitees,
    });

    return {
      data,
      thisPage: parseInt(thisPage),
      allPages: parseInt(allPages),
    };
  } catch (error) {
    const sError =
      error?.response?.data?.error?.message || "Unknown error occured";
    return { error: sError };
  }
};

export const updateEventInviteesService = async ({
  eventId,
  removingUserIds = [],
  addingUserIds = [],
}) => {
  try {
    const token = localStorage.getItem("token");
    const {
      data: { data, message },
    } = await putRequest({
      endPoint: eventsApi.invitees,
      token,
      body: {
        removingUserIds,
        eventId,
        addingUserIds,
      },
    });
    return {
      data,
      message,
    };
  } catch (error) {
    const sError =
      error?.response?.data?.error?.message || "Unkown error occured";
    return { error: sError };
  }
};

export const deleteEventService = async ({ eventIds }) => {
  try {
    const token = localStorage.getItem("token");
    const {
      data: { data, message },
    } = await delRequest({
      endPoint: eventsApi.list,
      token,
      query: {
        eventIds,
      },
    });
    return {
      data,
      message,
    };
  } catch (error) {
    const sError =
      error?.response?.data?.error?.message || "Unkown error occured";
    return { error: sError };
  }
};

export const updateEventStatusService = async ({ eventId, status }) => {
  try {
    const token = localStorage.getItem("token");
    const {
      data: { data, message },
    } = await putRequest({
      endPoint: eventsApi.status,
      token,
      body: {
        eventId,
        status,
      },
    });
    return {
      data,
      message,
    };
  } catch (error) {
    const sError =
      error?.response?.data?.error?.message || "Unkown error occured";
    return { error: sError };
  }
};

export const listEventsService = async ({
  page,
  limit,
  text,
  status,
  type,
  chatId,
  eventId,
  startDate,
  endDate,
}) => {
  try {
    const token = localStorage.getItem("token");
    const {
      data: {
        data,
        meta: { thisPage, allPages },
      },
    } = await getRequest({
      token,
      query: {
        page,
        limit,
        text,
        status,
        type,
        chatId,
        eventId,
        startDate,
        endDate,
      },
      endPoint: eventsApi.list,
    });

    return {
      data,
      thisPage: parseInt(thisPage),
      allPages: parseInt(allPages),
    };
  } catch (error) {
    const sError =
      error?.response?.data?.error?.message || "Unkown error occured";
    return { error: sError };
  }
};

export const fetchedLiveToken = async ({ id }) => {
  try {
    const token = localStorage.getItem("token");
    let res = await putRequest({
      body: { eventId: id },
      endPoint: eventsApi.live,
      token,
    });
    let data = res.data.data;
    let message = res.data.message;
    return {
      data,
      message,
    };
    /*     return {
      data: { accessToken: token },
    }; */
  } catch (error) {
    console.error(error);
    const sError =
      error?.response?.data?.error?.message || "Unkown error occured";
    return { error: sError };
  }
};

export const  listEventsTypeService = async ({ page, limit, pageId }) => {
  try {
    const token = localStorage.getItem("token");
    const {
      data: {
        data,
        meta: { thisPage, allPages },
      },
    } = await getRequest({
      token,
      query: {
        page,
        limit,
        pageId,
      },
      endPoint: eventsApi.type,
    });

    return {
      data,
      thisPage: parseInt(thisPage),
      allPages: parseInt(allPages),
    };
  } catch (error) {
    const sError =
      error?.response?.data?.error?.message || "Unkown error occured";
    return { error: sError };
  }
};
