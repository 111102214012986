import io from "socket.io-client";
import { fetchSingleChat } from "../services/chat";
import {
  addMessagToLocalStorage,
  changeMessagesUUIDLocalStorage,
  changeMessageUUIDTypeLocalStorage,
} from "../storage/chats";
import { changeUserStatus } from "../storage/users";
import {
  insertNewMessageAction,
  convertMessageTypeAction,
  insertNewChatACtion,
  updateChatInfo,
  leaveChatAction,
  deleteMessageACtion,
  starMessageAction,
  addReactToMessageAction,
  updateChatUsersAction,
  deleteChatUserAction,
} from "../store/actions/chat";
import {
  buzzHandler,
  insertNewNotification,
} from "../store/actions/notifcations";
import {
  ADD_SINGLE_CHAT,
  MESSAGE_SENT,
  SOCKETCONNECTED,
} from "../store/actions/types";

let socketAPI = process.env.REACT_APP_BASE_SOCKET_URL;

class Socket {
  constructor() {
    this.token = null;
    this.socket = null;
    this.dispatch = null;
    this.userId = null;
  }

  sendMessage(emit, data, callback) {
    return this.socket.emit(emit, data, (res) => {
      return callback && callback(res);
    });
  }

  listen() {
    let socket = this.socket;
    socket.on("connect", () => {
      this.sendMessage("join_my_chats", {});
      return this.dispatch({ type: SOCKETCONNECTED, payload: "connected" });
    });

    socket.io.on("reconnect_attempt", () => {
      return this.dispatch({ type: SOCKETCONNECTED, payload: "reconnecting" });
    });

    socket.io.on("error", (error) => {
      return;
    });

    socket.on("received_message", async (message) => {
      const isFetched = addMessagToLocalStorage(message?.chatId, message);
      let newChat;
      if (!isFetched) {
        let { chat } = await fetchSingleChat(
          {
            page: 1,
            limit: 50,
            chatId: message?.chatId,
          },
          true
        );
        await this.dispatch({ type: ADD_SINGLE_CHAT, payload: chat });
        newChat = chat;
      }
      if (message?.senderId !== this.userId || !message.feMark)
        return insertNewMessageAction(message);
      if (message?.feMark && message?.senderId === this.userId) {
        changeMessagesUUIDLocalStorage(message?.chatId, message?.feMark, {
          ...message,
          id: message?.id,
          uuid: message?.uuid,
          status: "sent",
        });
      }
      this.dispatch({
        type: MESSAGE_SENT,
        payload: {
          ...message,
          id: message?.id,
          uuid: message?.uuid,
          status: "sent",
          chat: message?.newChat ? newChat : undefined,
        },
      });

      return;
    });

    socket.on("message_converted", (message) => {
      changeMessageUUIDTypeLocalStorage(message);
      return convertMessageTypeAction(message);
    });

    socket.on("new_group", (data) => insertNewChatACtion(data));

    socket.on("user_online", (data) => {
      if (!data) return;
      const { userId, online } = data;
      if (this.userId !== userId) {
        changeUserStatus(userId, online);
      }
    });

    socket.on("notification", (data) =>
      insertNewNotification(data)(this.dispatch)
    );

    socket.on("update_chat_info", (data) =>
      updateChatInfo(data)(this.dispatch)
    );

    socket.on("group_leave", (data) =>
      leaveChatAction({ ...data })(this.dispatch)
    );
    socket.on("message_deleted", (data) =>
      deleteMessageACtion(data)(this.dispatch)
    );
    socket.on("message_starred", (data) =>
      starMessageAction(data)(this.dispatch)
    );
    socket.on("message_reacted", (data) => {
      let meReacted = false;
      const reactorUserId = data?.userId;
      if (reactorUserId == this.userId) meReacted = true;
      data["meReacted"] = meReacted;
      return addReactToMessageAction(data)(this.dispatch);
    });

    socket.on("update_chat_members", (data) =>
      updateChatUsersAction(data)(this.dispatch)
    );
    socket.on("delete_chat_members", (data) =>
      deleteChatUserAction(data)(this.dispatch)
    );
    socket.on("buzzed", (data = {}) => {
      /*      if (data?.sender?.id == this.userId) return; */

      buzzHandler(data)(this.dispatch);
    });
  }

  distroySocket() {
    this.socket.disconnect();
    this.socket = null;
  }
  connect(token, userId, dispatch) {
    if (this.socket?.connected) return;
    this.token = token;
    this.dispatch = dispatch;
    this.userId = userId;
    try {
      const socket = io(socketAPI, {
        reconnection: true,
        transports: ["websocket", "polling"],
        auth: {
          token,
        },
      });
      this.socket = socket;
      this.listen();
      return socket;
    } catch (error) {
      return null;
    }
  }
}

let socket = new Socket();
export { socket };
