import store from "../store";
import { CHANGE_USER_STATUS } from "../store/actions/types";

export const storeUsersToLO = async (usersByIds) => {
  try {
    let currentUsers = localStorage.getItem("users");
    if (!currentUsers) {
      currentUsers = { byId: {} };
    } else {
      currentUsers = JSON.parse(currentUsers);
    }

    localStorage.setItem(
      "users",
      JSON.stringify({
        byId: { ...currentUsers.byId, ...usersByIds },
      })
    );
  } catch (error) {
    localStorage.setItem(
      "users",
      JSON.stringify({
        byId: { ...usersByIds },
      })
    );
  }
};

export const changeUserStatus = (userId, isOnline) => {
  // let currentUsers = localStorage.getItem("users");
  // if (!currentUsers) {
  //   currentUsers = { byId: {} };
  // } else {
  //   currentUsers = JSON.parse(currentUsers);
  // }
  // let lastSeen;
  // if (isOnline) {
  // } else {
  //   lastSeen = new Date().toISOString();
  // }
  // let user = currentUsers.byId[userId];
  // currentUsers.byId[userId]["isOnline"] = isOnline;
  // if (isOnline) {
  //   currentUsers.byId[userId]["lastSeen"] = lastSeen;
  // }
  // if (user) {
  //   store.dispatch({ type: CHANGE_USER_STATUS, payload: user });
  // }

  // localStorage.setItem(
  //   "users",
  //   JSON.stringify({
  //     byId: { ...currentUsers.byId },
  //   })
  // );
};

export const fetchUsers = (usersById = []) => {
  let currentUsers = localStorage.getItem("users");
  if (!currentUsers) {
    currentUsers = { byId: {} };
  } else {
    currentUsers = JSON.parse(currentUsers);
  }
  let currentToReturn = {};

  usersById = usersById.filter((u) => u);
  usersById.map((u) => (currentToReturn[u.id] = u));

  for (let v in currentToReturn || {}) {
    currentToReturn[v] = currentUsers?.byId[v];
  }

  return currentToReturn;
};

export const fetchSingleUser = (userId) => {
  let currentUsers = localStorage.getItem("users");
  if (!currentUsers) {
    currentUsers = { byId: {} };
  } else {
    currentUsers = JSON.parse(currentUsers);
  }
  return currentUsers["byId"][userId];
};

export const changeUserDataLO = (userId, newData = {}) => {
  let currentUsers = localStorage.getItem("users");
  if (!currentUsers) {
    currentUsers = { byId: {} };
  } else {
    currentUsers = JSON.parse(currentUsers);
  }

  let user = currentUsers.byId[userId];

  if (user) {
    currentUsers.byId[userId] = { ...currentUsers.byId[userId], ...newData };
  }

  localStorage.setItem(
    "users",
    JSON.stringify({
      byId: { ...currentUsers.byId },
    })
  );
};
