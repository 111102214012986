import { APP_CONFIG } from "../actions/types";
import { createReducer } from "@reduxjs/toolkit";
import { logoIcon } from "../../Assets";

const initialState = {
  loading: true,
  subdomain: null,
  company: null,
  logo: logoIcon,
};

const appReducer = createReducer(initialState, (builder) => {
  builder.addCase(APP_CONFIG, (s, { payload = {} }) => {
    s.loading = false;
    s.subdomain = payload.subdomain;
    s.company = payload.company;
    if (payload.logo) s.logo = payload.logo;
  });
});

export default appReducer;
