import {
  SPEAKER_LOADING,
  LOAD_SPEAKER,
  LOAD_SPEAKER_ERROR,
  ADD_SPEAKER_EVENT,
  DELETE_SPEAKER,
} from "../actions/types";
import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  thisPage: 0,
  allPages: 0,
  error: false,
  loading: false,
};

const speakerReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(SPEAKER_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(LOAD_SPEAKER, (state, { payload }) => {
      state.data =
        payload?.thisPage === 1
          ? payload?.data
          : [...state?.data, ...payload?.data];
      state.thisPage = payload.thisPage;
      state.nextPage = payload.nextPage;

      state.loading = false;
    })
    .addCase(LOAD_SPEAKER_ERROR, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    })
    .addCase(ADD_SPEAKER_EVENT, (state, { payload }) => {
      state.data.unshift(payload);
      state.loading = false;
      state.error = false;
    })
    .addCase(DELETE_SPEAKER, (s, { payload }) => {
      s.data = s.data.filter((speaker) => speaker.id !== payload);
      s.loading = false;
    });
});

export default speakerReducer;
