import {
  CALENDAR_LOADING,
  LOAD_CALENDAR,
  LOAD_CALENDAR_ERROR,
} from "../actions/types";
import { createReducer } from "@reduxjs/toolkit";
const initialState = {
  data: [],
  thisPage: 0,
  allPages: 0,
  error: false,
  loading: false,
};

const calendarReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(CALENDAR_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(LOAD_CALENDAR, (state, { payload }) => {
      state.data = payload?.data;
      state.thisPage = payload?.meta?.thisPage;
      state.allPages = payload?.meta?.allPages;
      state.loading = false;
    })
    .addCase(LOAD_CALENDAR_ERROR, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
});

export default calendarReducer;
