import store from "../../../store/index";
import {
  CHATS_LOADING,
  CHATS_ERROR,
  CHATS_LOADED,
  INSERT_NEW_GROUP,
  SINGLE_CHAT_ERROR,
  SINGLE_CHAT_LOADED,
  INSERT_NEW_MESSAGE,
  UPDATE_CHATS_WHEN_MESSAGE_GOT,
  UPDATE_CHAT_UNSEEN_MESSAGE_COUNT,
  CONVERT_MESSAGE,
  SINGLE_CHAT_LOADING,
  UPDATE_CHAT_INFO,
  DELETE_CHAT,
  EMPTY_SINGLE_CHAT,
  SET_MESSAGE_DELETED,
  CLEAR_CHAT_MESSAGES,
  STARR_MESSAGE_CHANGE,
  CHANGE_MESSAGE_REACT,
  ADD_CHAT_MEMBERS,
  DELETE_CHAT_MEMBER,
  CHANGE_CHATS_TAB,
  THREADS_LOADING,
  GET_THREADS,
  THREADS_ERROR,
} from "../types";
import { socket } from "../../../connection/socket";
import {
  addChatToLocalStorageChats,
  changeLOMessage,
  getChatFromLocalStorage,
  storeChatsLocalStorageInFirstTime,
  updateChatInfoLocalStorage,
  addChatMembersInLOcalStorage,
  updateLocalStorageChatMute,
  deleteChatMembersInLOcalStorage,
  deleteChatLocalStorage,
} from "../../../storage/chats";
import {
  clearChatMessagesService,
  deleteChatService,
  fetchSingleChat,
  getChats,
  getThreads,
} from "../../../services/chat";
import { notifySound } from "../../../shared/audios";
import { updatePrivateBotService } from "../../../services/auth";
import { reactToMessageSocket } from "../../../services/messages";

export const insertNewMessageAction = (message, native = false) => {
  let state = store?.getState();

  const chatId = parseInt(message?.chatId);
  const willDispatch =
    parseInt(state?.singleChat?.chat?.id) === chatId ||
    parseInt(state?.singleChat?.chat?.otherUser?.id) ===
      parseInt(message?.senderId) ||
    parseInt(state?.singleChat?.chat?.otherUser?.id) ===
      parseInt(message?.receiverId);

  if (willDispatch) {
    store.dispatch({ type: INSERT_NEW_MESSAGE, payload: message });
  }

  message.chatId = chatId;
  store.dispatch({
    type: UPDATE_CHATS_WHEN_MESSAGE_GOT,
    payload: { message, inc: !willDispatch },
  });

  if (!native) {
    socket.sendMessage(willDispatch ? "message_read" : "message_received", {
      messageUuid: message?.uuid,
      messageId: message?.id,
    });
  }

  if (!willDispatch) {
    let chat = state?.chats?.data?.find((c) => c?.id === chatId);
    if (chat && !chat?.isMuted) notifySound();
  }
  return;
};

export const insertNewChatACtion = (chat) => {
  addChatToLocalStorageChats(chat.id, chat);
  store.dispatch({ type: INSERT_NEW_GROUP, payload: chat });
};

export const convertMessageTypeAction = (message) => {
  store.dispatch({ type: CONVERT_MESSAGE, payload: message });
};

export const getChatsAction =
  ({ page, limit, type = "all", firstTime }) =>
  (dispatch) => {
    dispatch({ type: CHATS_LOADING });
    return getChats({ page, limit, type })
      .then((allData) => {
        firstTime &&
          storeChatsLocalStorageInFirstTime({
            chats: allData.data,
            ...allData,
          });
        dispatch({
          type: CHATS_LOADED,
          payload: { ...allData, reset: firstTime },
        });
      })
      .catch((e) => {
        console.error(e);
        dispatch({ type: CHATS_ERROR, payload: "error" });
      });
  };

export const destroySingleChatAction = () => (dispatch) => {
  dispatch({ type: EMPTY_SINGLE_CHAT });
};

export const getMessagesAction =
  ({ page, limit, chatId, getUsersChat, loadMore, callback }) =>
  async (dispatch) => {
    if (!loadMore) dispatch({ type: SINGLE_CHAT_LOADING });

    if (!loadMore) {
      let fromLo = null;
      fromLo = await getChatFromLocalStorage(chatId);
      if (fromLo?.chat?.fetched) {
        dispatch({
          type: UPDATE_CHAT_UNSEEN_MESSAGE_COUNT,
          payload: { count: 0, chatId: parseInt(chatId) },
        });

        dispatch({
          type: SINGLE_CHAT_LOADED,
          payload: {
            loadMore,
            messages: {
              thisPage: fromLo?.messages.meta.thisPage,
              allPages: fromLo?.messages.meta.allPages,
              count: fromLo?.messages.meta.count,
              data: fromLo?.messages?.data,
            },
            chat: fromLo.chat,
            hasMore: fromLo.hasMore,
          },
        });
        if (callback) callback();
        return;
      }
    }

    return fetchSingleChat(
      { page, limit, chatId, getUsersChat, loadMore },
      !loadMore
    )
      .then(
        ({ data, thisPage, allPages, chat, hasMore, count, otherChats }) => {
          thisPage === 1 &&
            dispatch({
              type: UPDATE_CHAT_UNSEEN_MESSAGE_COUNT,
              payload: { count: 0, chatId: parseInt(chatId) },
            });

          dispatch({
            type: SINGLE_CHAT_LOADED,
            payload: {
              messages: { data, thisPage, allPages, count },
              chat,
              otherChats,
              loadMore,
              hasMore,
            },
          });
          if (callback) callback();
        }
      )
      .catch((e) => {
        console.error(e);
        if (callback) callback(e);
        dispatch({ type: SINGLE_CHAT_ERROR, payload: "error" });
      });
  };

export const updateChatInfo = (data) => (dispatch) => {
  dispatch({ type: UPDATE_CHAT_INFO, payload: data });
  updateChatInfoLocalStorage(data);
};

export const updatePrivateBotInfo = (body, cb) => (dispatch) => {
  updatePrivateBotService({ data: body, cb }).then((res) => {
    const { data } = res;
    if (data) {
      dispatch({ type: UPDATE_CHAT_INFO, payload: data });
      updateChatInfoLocalStorage(data);
    }
  });
};

export const leaveChatAction =
  ({ chatId }) =>
  (dispatch) => {
    dispatch({ type: DELETE_CHAT, payload: chatId });
  };

export const deleteMessageACtion = (data) => (dispatch) => {
  const { uuid = [], chatId } = data;

  if (chatId && uuid) {
    dispatch({ type: SET_MESSAGE_DELETED, payload: { uuid, chatId } });
    changeLOMessage({
      uuids: uuid,
      chatId,
      newToUpdate: {
        inChatDeletedAt: String(new Date()),
      },
    });
  }
};

export const starMessageAction = (data) => (dispatch) => {
  const chatId = data?.chatId;
  const uuid = data?.message?.uuid;
  let starred = data?.message?.starred;
  dispatch({
    type: STARR_MESSAGE_CHANGE,
    payload: {
      uuid: [uuid],
      starred,
      chatId,
    },
  });
  changeLOMessage({
    uuids: [uuid],
    chatId,
    newToUpdate: {
      starred,
    },
  });
};

export const clearChatMessagesAction = (chatId) => {
  clearChatMessagesService({ chatId });
  updateChatInfoLocalStorage({
    id: chatId,
    messages: {
      data: [],
      meta: {},
      lastMessage: undefined,
    },
  });
  store.dispatch({ type: CLEAR_CHAT_MESSAGES, payload: { chatId } });
};

export const deleteChatAction = (chatId) => {
  deleteChatService({ chatId });
  deleteChatLocalStorage({ id: chatId });
  store.dispatch({ type: DELETE_CHAT, payload: chatId });
};

export const updateChatMuteACtion = (chatId, isMuted) => {
  updateLocalStorageChatMute({ chatId, isMuted });
  updateChatInfoLocalStorage({
    id: chatId,
    isMuted,
  });
  store.dispatch({ type: UPDATE_CHAT_INFO, payload: { id: chatId, isMuted } });
};

export const reactToMessageAction = ({
  uuid,
  chatId,
  deleteReact,
  reactionTypeId,
}) => {
  return reactToMessageSocket({ uuid, chatId, deleteReact, reactionTypeId });
};

export const addReactToMessageAction =
  (rawData = {}) =>
  (dispatch) => {
    const {
      uuid,
      chatId,
      deleteReact,
      reactionTypeId,
      meReacted,
      changed,
      userReacts,
    } = rawData;
    dispatch({
      type: CHANGE_MESSAGE_REACT,
      payload: {
        uuid,
        chatId,
        deleteReact,
        reactionTypeId,
        meReacted,
        changed,
        userReacts,
      },
    });

    /*  changeLOMessage({
      uuids: [uuid],
      chatId,
      newToUpdate: message,
    }); */
    /* 
    CHANGE_MESSAGE_REACT
    
    */
  };

export const updateChatUsersAction =
  (rawData = {}) =>
  (dispatch) => {
    const { chatId = 0, newUsers = [] } = rawData;
    dispatch({ type: ADD_CHAT_MEMBERS, payload: { chatId, newUsers } });
    addChatMembersInLOcalStorage(chatId, newUsers);
  };

export const deleteChatUserAction =
  (rawData = {}) =>
  (dispatch) => {
    const { chatId, userId } = rawData;
    dispatch({ type: DELETE_CHAT_MEMBER, payload: { chatId, userId } });
    deleteChatMembersInLOcalStorage(chatId, userId);
  };

export const changeChatsTabAction =
  ({ tab }) =>
  (dispatch) =>
    dispatch({ type: CHANGE_CHATS_TAB, payload: tab });

export const getThreadsAction =
  ({ page, limit, chatId }) =>
  (dispatch) => {
    dispatch({ type: THREADS_LOADING });
    return getThreads({ page, limit, chatId })
      .then((allData) => {
        dispatch({
          type: GET_THREADS,
          payload: { ...allData },
        });
      })
      .catch((e) => {
        console.error(e);
        dispatch({ type: THREADS_ERROR, payload: "error" });
      });
  };
