import {
  ADD_ORDER,
  ADD_TO_CART,
  CHECKOUT,
  DECREASE_ITEM,
  INCREASE_ITEM,
  LIST_CART,
  LIST_CART_ERROR,
  LIST_CART_LOADING,
  ORDER,
  ORDER_ERROR,
  REMOVE_FROM_CART,
} from "./../actions/types";
import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  error: "",
  loading: false,
  data: {},
  thisPage: 0,
  nextPage: 0,
  id: "",
  tokens: undefined,
  orderError: "",
};

const cartReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(LIST_CART_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(LIST_CART_ERROR, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    })
    .addCase(LIST_CART, (state, { payload }) => {
      const isLoggedIn = localStorage.getItem("isLoggedIn");
      if (!isLoggedIn) {
        let cart = JSON.parse(localStorage.getItem("cart"));
        state.data = cart || [];
      } else if (payload.id) {
        state.id = payload.id;
        state.data[payload.page.id] = payload?.cartItems;
      }
      state.loading = false;
    })
    .addCase(ADD_TO_CART, (state, { payload }) => {
      const isLoggedIn = localStorage.getItem("isLoggedIn");
      if (!isLoggedIn) {
        let cart = JSON.parse(localStorage.getItem("cart"));
        if (cart) {
          let index;
          if (cart[payload.pageId]) {
            index = cart[payload.pageId]?.findIndex(
              (e) => e.product.id === payload?.product?.id
            );
            if (index >= 0) {
              cart[payload.pageId][index].amount += payload.amount;
            } else {
              cart[payload.pageId] = cart[payload.pageId]
                ? [...cart[payload.pageId], payload]
                : [payload];
            }
          }
        } else {
          cart = {};
          cart[payload.pageId] = [payload];
        }
        localStorage.setItem("cart", JSON.stringify(cart)); ///local storage
      }

      let index;
      if (state.data && state.data[payload.pageId]) {
        index = state.data[payload.pageId].findIndex(
          (e) => e.product.id === payload?.product?.id
        );
      }
      if (index >= 0) {
        state.data[payload.pageId][index].amount += payload.amount;
      } else {
        state.data[payload.pageId] =
          state?.data && state?.data[payload.pageId]
            ? [...state?.data[payload.pageId], payload]
            : [payload];
      }
      state.loading = false;
    })
    .addCase(REMOVE_FROM_CART, (state, { payload }) => {
      const isLoggedIn = localStorage.getItem("isLoggedIn");
      if (!isLoggedIn) {
        let cart = JSON.parse(localStorage.getItem("cart"));
        cart[payload?.pageId] = cart[payload?.pageId]?.filter(
          (e) => e.product.id != payload.id
        );
        localStorage.setItem("cart", JSON.stringify(cart));
      }

      state.data[payload?.pageId] = state?.data[payload?.pageId]?.filter(
        (e) => e?.id !== payload?.id
      );
    })
    .addCase(INCREASE_ITEM, (state, { payload }) => {
      const isLoggedIn = localStorage.getItem("isLoggedIn");
      if (!isLoggedIn) {
        let cart = JSON.parse(localStorage.getItem("cart"));
        const item = cart[payload?.pageId]?.filter(
          (e) => e.product.id == payload?.productId
        )[0];
        item["amount"] = item["amount"] + 1;
        cart[payload?.pageId] = [
          ...cart[payload?.pageId]?.filter(
            (e) => e.product.id != payload.productId
          ),
          item,
        ];
        localStorage.setItem("cart", JSON.stringify(cart));
      }

      let index = state?.data[payload?.pageId]?.findIndex(
        (e) => e?.product?.id === payload?.productId
      );
      state.data[payload?.pageId][index].amount =
        state.data[payload?.pageId][index].amount + 1;
    })
    .addCase(DECREASE_ITEM, (state, { payload }) => {
      const isLoggedIn = localStorage.getItem("isLoggedIn");
      if (!isLoggedIn) {
        let cart = JSON.parse(localStorage.getItem("cart"));
        const item = cart[payload?.pageId]?.filter(
          (e) => e.product.id == payload?.productId
        )[0];
        item["amount"] = item["amount"] - 1;
        cart[payload?.pageId] = [
          ...cart[payload?.pageId]?.filter(
            (e) => e.product.id != payload.productId
          ),
          item,
        ];
        localStorage.setItem("cart", JSON.stringify(cart));
      }

      let index = state?.data[payload?.pageId]?.findIndex(
        (e) => e?.product?.id === payload?.productId
      );
      state.data[payload?.pageId][index].amount =
        state.data[payload?.pageId][index].amount - 1;
    })
    .addCase(CHECKOUT, (state, { payload }) => {
      state.data[payload?.pageId] = [];
    })
    .addCase(ORDER, (state) => {
      state.orderErrors = "";
      state.tokens = undefined;
    })
    .addCase(ORDER_ERROR, (state, { payload }) => {
      state.orderErrors = payload;
    })
    .addCase(ADD_ORDER, (state, { payload }) => {
      console.log(payload.paymentToken);
      state.tokens = payload.paymentToken;
    });
});

export default cartReducer;
