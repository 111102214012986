import {
  GET_ORDER_STATUSES,
  LIST_ORDERS,
  LIST_ORDERS_ERROR,
  LIST_ORDERS_LOADING,
  SELECT_ORDER,
  SET_FILTERS,
} from "../actions/types";
import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  error: "",
  loading: false,
  data: [],
  thisPage: 0,
  nextPage: 0,
  hasMore: true,
  currentOrder: null,
  orderStatuses: {},
  filters: {},
};

const ordersReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(LIST_ORDERS_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(LIST_ORDERS_ERROR, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    })
    .addCase(SELECT_ORDER, (state, { payload }) => {
      state.currentOrder = payload;
    })
    .addCase(GET_ORDER_STATUSES, (state, { payload }) => {
      /*     localStorage.setItem("orderStatuses", JSON.stringify(payload)); */
      state.orderStatuses = payload;
    })
    .addCase(SET_FILTERS, (state, { payload }) => {
      state.filters = { ...state.filters, ...payload };
    })
    .addCase(LIST_ORDERS, (state, { payload }) => {
      state.loading = false;

      state.data =
        payload?.thisPage === 1
          ? payload?.data
          : [...state?.data, ...payload?.data];

      if (payload?.thisPage === 1) {
        state.currentOrder = null;
      }

      state.thisPage = payload.thisPage;
      state.nextPage = payload.nextPage;
      state.hasMore = payload.hasMore;
    });
});

export default ordersReducer;
