import {
  HOME_LOADING,
  HOME_ERROR,
  GET_HOME,
  INSERT_HOME_DATA,
  DELETE_HOME_DATA,
} from "../actions/types";
import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  error: "",
  loading: false,
  data: [],
  count: 0,
  thisPage: 0,
  hasMore: true,
};

const homeReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(HOME_LOADING, (s) => {
      s.loading = true;
      s.error = false;
    })
    .addCase(HOME_ERROR, (s, { payload }) => {
      s.loading = false;
      s.error = payload;
    })
    .addCase(GET_HOME, (s, { payload }) => {
      let byOrder = [];
      payload.data.forEach((e) => {
        byOrder[e?.order - 1 > 0 ? e?.order - 1 : 0] = e;
      });

      byOrder = Array.from(byOrder, (val) => val ?? {});
      if (payload.thisPage === 1 && byOrder?.length < 12) {
        byOrder = [...byOrder, ...Array(12 - byOrder?.length)?.map(() => ({}))];
      }

      s.data = payload?.thisPage === 1 ? byOrder : [...s.data, ...byOrder];
      s.thisPage = payload.thisPage;
      s.hasMore = payload.hasMore;
      s.count = payload.count;
      s.loading = false;
    })
    .addCase(INSERT_HOME_DATA, (s, { payload }) => {
      s.data[payload?.order - 1] = payload;
    })
    .addCase(DELETE_HOME_DATA, (s, { payload }) => {
      s.data[payload - 1] = {};
    });
    
});

export default homeReducer;
