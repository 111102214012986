import moment from "moment";
import {
  LOAD_POSTS,
  LOAD_POSTS_ERROR,
  LOAD_POSTS_LOADING,
  INSERT_POST,
  CHANGE_REACT,
  DELETE_POST,
  CHOOSE_POLL_OPTION,
  UPDATE_POST,
  JOIN_POST_EVENT,
  DELETE_CHOICE_POLL_OPTION,
  ADD_SPEAKER_EVENT,
  UPDATE_POLL_OPTION,
} from "../actions/types";
import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  error: "",
  loading: false,
  data: {
    byId: {},
    allIds: [],
  },
  count: 0,
  thisPage: 0,
  hasMore: true,
};
const postsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(JOIN_POST_EVENT, (s, { payload }) => {
      const { postId } = payload;
      let postFound = s?.data?.byId[postId];
      if (postFound.type.event) {
        s.data.byId[postId]["type"]["event"]["status"] = {
          status: "joined",
          createdAt: String(new Date()),
        };
      }
    })
    
    .addCase(UPDATE_POST, (s, { payload }) => {
      let postFound = s?.data?.byId[payload?.id];
      if (postFound) {
        s.data.byId[payload?.id] = {
          ...payload,
        };
      }
    })
    .addCase(CHOOSE_POLL_OPTION, (s, { payload }) => {
      let postFound = s?.data?.byId[payload?.postId];

      if (postFound?.type?.poll) {
        s.data.byId[payload?.postId].type.poll = payload?.poll;
      }
    })
    .addCase(UPDATE_POLL_OPTION, (s, { payload }) => {
      let postFound = s?.data?.byId[payload?.postId];

      if (postFound?.type?.poll) {
        const questionIndex = s.data.byId[
          payload?.postId
        ].type.poll?.questions?.findIndex(
          (question) => question.id === payload?.questionId
        );

        const optionIndex = s.data.byId[payload?.postId].type.poll.questions[
          questionIndex
        ]?.options?.findIndex((option) => option.id === payload?.option?.id);

        s.data.byId[payload?.postId].type.poll.questions[questionIndex].options[
          optionIndex
        ] = payload?.option;
      }
    })
    .addCase(DELETE_CHOICE_POLL_OPTION, (s, { payload }) => {
      let postFound = s?.data?.byId[payload?.postId];
      if (postFound?.type?.poll) {
        const qIndex = s.data.byId[
          payload?.postId
        ].type.poll?.questions?.findIndex((e) => e?.id === payload?.questionId);

        s.data.byId[payload?.postId].type.poll.questions[
          qIndex
        ].myChosenOption = null;
      }
    })
    .addCase(DELETE_POST, (s, { payload }) => {
      delete s.data.byId[payload];
      const index = s.data.allIds.indexOf(payload);
      if (index > -1) {
        s.data.allIds.splice(index, 1); // 2nd parameter means remove one item only
      }
    })
    .addCase(CHANGE_REACT, (s, { payload }) => {
      const { deleteReact, postId, reactionTypeId } = payload;

      if (s.data.byId[postId]) {
        const userReaction = s.data.byId[postId]["userReaction"];
        if (deleteReact) {
          s.data.byId[postId]["userReaction"] = false;
          s.data.byId[postId]["totalReactionCount"]--;
          s.data.byId[postId]["reacts"]["total"]--;
          s.data.byId[postId]["reacts"][userReaction]--;
        } else {
          s.data.byId[postId]["userReaction"] = reactionTypeId;
          if (!userReaction) {
            s.data.byId[postId]["reacts"]["total"]++;
            s.data.byId[postId]["totalReactionCount"]++;
            s.data.byId[postId]["reacts"][reactionTypeId]++;
          } else {
            s.data.byId[postId]["reacts"][userReaction]--;
            s.data.byId[postId]["reacts"][reactionTypeId]++;
            s.data.byId[postId]["userReaction"] = reactionTypeId;
          }
        }
      }
    })
    .addCase(LOAD_POSTS_LOADING, (s) => {
      s.loading = true;
      s.error = false;
    })
    .addCase(LOAD_POSTS_ERROR, (s, { payload }) => {
      s.loading = false;
      s.error = payload;
    })
    .addCase(LOAD_POSTS, (s, { payload }) => {
      s.data.byId =
        payload?.thisPage === 1
          ? payload.byId
          : { ...s.data?.byId, ...payload?.byId };

      if (payload?.sortBy === "date") {
        if (payload?.thisPage === 1) {
          s.data.allIds = payload.allIds;
        } else {
          Object.entries(payload.allIds).forEach(([key, value]) => {
            s.data.allIds[key] = [...(s.data.allIds[key] || []), value];
          });
        }
      } else {
        s.data.allIds =
          payload?.thisPage === 1
            ? payload.allIds
            : [...s.data?.allIds, ...payload?.allIds];
      }
      s.thisPage = payload.thisPage;
      s.hasMore = payload.hasMore;
      s.count = payload.count;
      s.loading = false;
    })
    .addCase(INSERT_POST, (s, { payload }) => {
      s.data.byId[payload?.id] = {
        ...payload,
        totalReactionCount: 0,
        userReaction: false,
        reacts: {
          1: 0,
          2: 0,
          3: 0,
          4: 0,
          5: 0,
          6: 0,
          7: 0,
          8: 0,
          9: 0,
          total: 0,
        },
      };

      if (payload?.addByDate) {
        const date = `${moment().year()}-${moment().month() + 1}`;
        s.data.allIds[date]
          ? s.data.allIds[date].unshift(payload)
          : (s.data.allIds[date] = [payload]);
      } else {
        s.data.allIds.unshift(payload?.id);
      }
    });
});

export default postsReducer;
