import {
  GET_PAGE_ANALYTICS,
  GET_PAGE_ANALYTICS_LOADING,
} from "../actions/types";
import { createReducer } from "@reduxjs/toolkit";
const initials = {
  pageId: null,
  overview: {
    fetched: false,
    loading: false,
    error: null,
    data: {},
  },
  timeline: {
    fetched: false,
    loading: false,
    error: null,
    data: {},
  },
  sponsors: {
    fetched: false,
    loading: false,
    error: null,
    data: {},
  },
  speakers: {
    fetched: false,
    loading: false,
    error: null,
    data: {},
  },
};

const analyticsReducer = createReducer(initials, (builder) => {
  builder.addCase(GET_PAGE_ANALYTICS_LOADING, (s, { payload }) => {
    const { type } = payload;
    s[type].loading = true;
  });
  builder.addCase(GET_PAGE_ANALYTICS, (s, { payload }) => {
    const { type, data } = payload;
    s[type].data = { ...data };
    s[type].fetched = true;
    s[type].loading = false;
  });
});

export default analyticsReducer;
