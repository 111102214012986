import { Navigate, Route, Routes } from "react-router-dom";
import { lazy, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import SuspenseComponent from "../Components/SuspenseComponent";
import Loader from "../Components/Loader";
import { AppLoader } from "../Components/AppLoader";
import { appConfigAction } from "../store/actions/app/index";

const NotFound = lazy(() => import("../Pages/NotFound"));
const LoginPage = lazy(() => import("../Pages/Login"));
const Register = lazy(() => import("../Pages/Register"));
const Main = lazy(() => import("../Main"));
const ResetPassword = lazy(() => import("../Pages/ResetPassword"));

export const Router = ({ mode, changeMode }) => {
  const dispatch = useDispatch();
  const { isLoading: isAuthLoading, isAuthenticated } = useSelector(
    (s) => s?.auth
  );

  useEffect(() => {
    dispatch(appConfigAction());
  }, [dispatch]);

  if (isAuthLoading) return <AppLoader loader={<Loader height={64} />} />;

  return (
    <>
      <Routes>
        <Route
          path="/*"
          element={
            <Main
              mode={mode}
              changeMode={changeMode}
              isAuthenticated={isAuthenticated}
            />
          }
        />

        <Route
          path="login"
          element={
            <SuspenseComponent>
              <LoginPage />
            </SuspenseComponent>
          }
        />
        <Route
          path="register/*"
          element={
            <SuspenseComponent>
              <Register />
            </SuspenseComponent>
          }
        />
        <Route
          path="reset-password"
          element={
            <SuspenseComponent>
              <ResetPassword />
            </SuspenseComponent>
          }
        />

        <Route
          path="404"
          element={
            <SuspenseComponent>
              <NotFound />
            </SuspenseComponent>
          }
        />
        <Route path="*" element={<Navigate to="/404" />} />
        <Route
          path="404"
          element={
            <SuspenseComponent>
              <NotFound />
            </SuspenseComponent>
          }
        />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
    </>
  );
};

export default Router;
