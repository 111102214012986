import {
  ADD_ADDRESS,
  LIST_ADDRESS,
  LIST_ADDRESS_ERROR,
  LIST_ADDRESS_LOADING,
  REMOVE_ADDRESS,
  UPDATE_ADDRESS,
} from "./../actions/types";
import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  error: "",
  loading: false,
  data: {},
};

const addressReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(LIST_ADDRESS_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(LIST_ADDRESS_ERROR, (state, { payload }) => {
      state.error = payload;
    })
    .addCase(LIST_ADDRESS, (state, { payload }) => {
      state.data = payload?.data;
      state.loading = false;
    })
    .addCase(ADD_ADDRESS, (state, { payload }) => {
      state.data.push(payload);
    })
    .addCase(UPDATE_ADDRESS, (state, { payload }) => {
      let index;
      state?.data?.forEach((e) => {
        if (e?.id === payload?.id) index = state?.data?.indexOf(e);
      });
      state.data[index] = payload;
    })
    .addCase(REMOVE_ADDRESS, (state, { payload }) => {
      state.data = state.data.filter((e) => e.id !== payload);
    });
});

export default addressReducer;
