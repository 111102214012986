import {
  INDUSTRY_LOADING,
  LOAD_INDUSTRY,
  LOAD_INDUSTRY_ERROR,
} from "../actions/types";
import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  thisPage: 0,
  count: 0,
  allPages: 0,
  error: false,
  loading: false,
};

const industryReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(INDUSTRY_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(LOAD_INDUSTRY, (state, { payload }) => {
      state.data =
        payload?.thisPage === 1
          ? payload?.data
          : [...state?.data, ...payload?.data];
      state.thisPage = payload.thisPage;
      state.nextPage = payload.nextPage;
      state.count = payload.count;

      state.loading = false;
    })
    .addCase(LOAD_INDUSTRY_ERROR, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
});

export default industryReducer;
