import {
  postRequest,
  getRequest,
  putRequest,
  delRequest,
} from "../connection/network";
import { socket } from "../connection/socket";
import {
  addChatToLocalStorageChats,
  storeFirstTimeChatLocalStorage,
  loadMoreMessagesToLocalStorage,
  getMutedChats,
} from "../storage/chats";
import { fetchSingleUser, storeUsersToLO } from "../storage/users";
import store from "../store";
import { SET_CURRENT_USERS } from "../store/actions/types";

const endPoints = {
  createGroup: "chat/group",
  getChats: "chat",
  media: "chat/messages/media",
  messages: "chat/messages",
  members: "chat/members",
  clear: "chat/clear",
  delete: "chat/group",
  invite: "chat/invite",
  acceptInvitation: "chat/invite/accept",
  getThreads: "chat/thread",
};

const createNewGroupService = async (groupData) => {
  try {
    const token = localStorage.getItem("token");
    const {
      data: { data },
    } = await postRequest({
      endPoint: endPoints.createGroup,
      token,
      body: groupData,
    });
    return { data };
  } catch (e) {
    const { error } = e?.response?.data;
    return { error };
  }
};

export const createInviteLinkService = async ({ sendData }) => {
  try {
    const token = localStorage.getItem("token");
    const {
      data: { data },
    } = await postRequest({
      endPoint: endPoints.invite,
      token,
      body: sendData,
    });
    return data;
  } catch (e) {
    const { error } = e?.response?.data;
    return { error };
  }
};

export const acceptThreadInvitationService = async ({ sendData }) => {
  try {
    const token = localStorage.getItem("token");
    const {
      data: { data },
    } = await postRequest({
      endPoint: endPoints.acceptInvitation,
      token,
      body: { ...sendData },
    });
    return data;
  } catch (e) {
    const { error } = e?.response?.data;
    return { error };
  }
};

const getChats = async ({ page, limit, type }) => {
  try {
    const token = localStorage.getItem("token");
    const { data: resData } = await getRequest({
      token,
      query: { page, limit, type },
      endPoint: endPoints.getChats,
    });

    let {
      meta,
      data: { chats, usersById },
    } = resData;
    let mutedChats = getMutedChats();
    chats = chats.map((chat) => {
      if (mutedChats[chat?.id]) {
        chat.isMuted = true;
      } else {
        chat.isMuted = false;
      }
      return chat;
    });

    let data = chats;

    let pagination = {
      thisPage: {
        groups: parseInt(meta.thisPage),
        private: parseInt(meta.thisPage),
        thread: parseInt(meta.thisPage),
        bots: parseInt(meta.thisPage),
      },
      hasMore: {
        groups: true,
        private: true,
        thread: true,
        bots: true,
      },
    };

    if (type !== "all") {
      pagination["thisPage"] = {
        [type]: parseInt(meta.thisPage),
      };

      if (chats?.length < limit) {
        pagination["hasMore"] = {
          [type]: false,
        };
      }
    }

    storeUsersToLO(usersById);
    return {
      data,
      firstTime: type === "all" ? true : false,
      pagination,
    };
  } catch (error) {
    throw error;
  }
};

const sendMedia = async (data, cb) => {
  try {
    const token = localStorage.getItem("token");
    await postRequest({ body: data, token, endPoint: endPoints.media });
    cb && cb(null);
  } catch (error) {
    cb && cb(error);
    console.error(error);
  }
};
export const searchInChatMessagesService = async ({
  page,
  limit,
  chatId,
  tag,
  starred,
}) => {
  try {
    const token = localStorage.getItem("token");
    const { data } = await getRequest({
      token,
      query: { page, limit, chatId, tag, starred },
      endPoint: endPoints.messages,
    });

    data.meta.thisPage = parseInt(data.meta.thisPage);
    data.meta.allPages = parseInt(data.meta.allPages);

    return { data };
  } catch (e) {
    const { error } = e?.response?.data;
    return { error };
  }
};
export const fetchSingleChat = async (
  { page, limit, chatId, loadMore, getUsersChat },
  insertNew
) => {
  const token = localStorage.getItem("token");
  const { data: resData } = await getRequest({
    token,
    query: { page, limit, chatId, getUsersChat },
    endPoint: endPoints.messages,
  });

  const {
    meta,
    data: { messages, chat, usersById, otherChats },
  } = resData;
  let hasMore = true;

  if (messages?.length < limit) {
    hasMore = false;
  }

  let mutedChats = getMutedChats();
  if (mutedChats[chat?.id]) {
    chat["isMuted"] = true;
  } else {
    chat["isMuted"] = false;
  }
  storeUsersToLO(usersById);
  meta.thisPage = parseInt(meta.thisPage);
  meta.allPages = parseInt(meta.allPages);
  meta.count = parseInt(meta.count);
  if (insertNew) {
    await addChatToLocalStorageChats(chat.id, {
      ...chat,
      otherChats,
      messages: { data: messages, meta, hasMore },
      hasMore,
    });
  }

  if (!loadMore) {
    await storeFirstTimeChatLocalStorage(chatId, {
      ...chat,
      messages: { data: messages, hasMore, meta },
      otherChats,
      hasMore,
    });
  } else {
    console.log("Loading more");
    await loadMoreMessagesToLocalStorage(chatId, {
      data: messages,
      meta,
      hasMore,
    });
  }
  let otherUser = chat?.otherUser;

  if (otherUser) {
    chat.otherUser = fetchSingleUser(otherUser?.id);
  }
  store.dispatch({
    type: SET_CURRENT_USERS,
    payload: usersById || {},
  });

  return {
    data: messages,
    chat,
    otherChats,
    hasMore,
    ...(meta || {}),
  };
};

export const addMembersToGroup = async ({ chatId, userIds }) => {
  try {
    const token = localStorage.getItem("token");
    const {
      data: { data },
    } = await postRequest({
      endPoint: endPoints.members,
      token,
      body: { chatId, userIds },
    });
    return { data };
  } catch (e) {
    const { error } = e?.response?.data;
    return { error };
  }
};

export const updateGroupInfo = async ({ cb, data: body }) => {
  try {
    const token = localStorage.getItem("token");
    const {
      data: { data },
    } = await putRequest({
      endPoint: endPoints.createGroup,
      token,
      body,
    });
    if (cb) cb(null);
    return { data };
  } catch (e) {
    const { error } = e?.response?.data;
    if (cb) cb(error);
    return { error };
  }
};

export const leaveGroupService = async ({ chatId }) => {
  socket.sendMessage("leave_group", { chatId });
};

export const getChatMediaService = async ({
  chatId,
  type = "media",
  page,
  limit,
}) => {
  try {
    const token = localStorage.getItem("token");
    const {
      data: {
        data,
        meta: { thisPage, allPages },
      },
    } = await getRequest({
      token,
      query: {
        page,
        limit,
        type,
        chatId,
      },
      endPoint: endPoints.media,
    });

    return {
      data,
      thisPage: parseInt(thisPage),
      allPages: parseInt(allPages),
    };
  } catch (error) {
    const sError =
      error?.response?.data?.error?.message || "Unknown error occured";
    return { error: sError };
  }
};

export const clearChatMessagesService = async ({ chatId }) => {
  try {
    const { data } = await delRequest({
      endPoint: endPoints.clear,
      query: { chatId },
      token: localStorage.getItem("token"),
    });
    return { data };
  } catch (error) {
    const sError =
      error?.response?.data?.error?.message || "Unknown error occured";
    return { error: sError };
  }
};

export const deleteChatService = async ({ chatId }) => {
  try {
    const { data } = await delRequest({
      endPoint: endPoints.delete,
      query: { chatId },
      token: localStorage.getItem("token"),
    });
    return { data };
  } catch (error) {
    const sError =
      error?.response?.data?.error?.message || "Unknown error occured";
    return { error: sError };
  }
};

const getThreads = async ({ page, limit, chatId }) => {
  try {
    const token = localStorage.getItem("token");
    const { data: resData } = await getRequest({
      token,
      query: { page, limit, chatId },
      endPoint: endPoints.getThreads,
    });

    let {
      meta,
      data: { threads, usersById },
    } = resData;
    // let mutedChats = getMutedChats();
    // threads = threads.map((chat) => {
    //   if (mutedChats[chat?.id]) {
    //     chat.isMuted = true;
    //   } else {
    //     chat.isMuted = false;
    //   }
    //   return chat;
    // });

    let data = threads;

    let pagination = {
      thisPage: parseInt(meta.thisPage),
      hasMore: true,
    };

    pagination["thisPage"] = parseInt(meta.thisPage);

    if (threads?.length < limit) {
      pagination["hasMore"] = false;
    }

    // storeUsersToLO(usersById);
    return {
      data,
      firstTime: false,
      pagination,
    };
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export { createNewGroupService, getChats, sendMedia, getThreads };
