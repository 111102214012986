import { getRequest } from "../connection/network";
let endPoints = {
  sticker: "sticker",
  pack: "sticker/pack",
};
export const fetchMoreStickersByPack = async ({ packId, limit, page }) => {
  let token = localStorage.getItem("token");
  try {
    const { data } = await getRequest({
      endPoint: endPoints.sticker,
      token,
      query: { packId, limit, page },
    });
    formatAndSaveStickers(data?.data, { packId, limit, page });
  } catch (error) {
    console.error(error);
  }
};

const formatAndSaveStickers = (data = [], { packId, limit, page }) => {
  try {
    let packs = localStorage.getItem("stickerPacks") || "{}";
    packs = JSON.parse(packs || {});

    if (packs[packId] && packs[packId]["stickers"]) {
      packs[packId] = {
        ...packs[packId],
        limit,
        page,
        stickers: [...packs[packId]["stickers"], ...data],
        hasMore: data?.length === limit,
      };
    }
    localStorage?.setItem("stickerPacks", JSON.stringify(packs));
  } catch (error) {
    console.error(error);
  }
};

export const getPackStickers = async (packId, page) => {
  let packs = localStorage.getItem("stickerPacks");
  packs = JSON.parse(packs || {});

  let pack = packs[packId];

  if (!pack || pack?.page < page) {
    await fetchMoreStickersByPack({ page, limit: 14, packId });
    packs = localStorage.getItem("stickerPacks") || "{}";
    packs = JSON.parse(packs || {});
    pack = packs[packId];
  }

  return pack;
  /*   let isInLO = localStorage.getItem("stickers") || "{}";
  const stickersInLO = JSON.parse(isInLO || {});

  let stickers = Object.values(stickersInLO)?.map((sticker) => sticker);
  return stickers; */
};

/// Packs
export const fetchStickerPacks = async () => {
  let token = localStorage.getItem("token");
  try {
    const { data } = await getRequest({
      endPoint: endPoints.pack,
      token,
    });
    formatAndSavePacks(data?.data);
  } catch (error) {
    console.error(error);
  }
};

const formatAndSavePacks = (packs = []) => {
  let packsToParse = "{}";
  packsToParse = JSON.parse(packsToParse || {});
  packs.map((pack) => {
    return (packsToParse[pack?.id] = {
      ...pack,
      limit: 14,
      page: 1,
      hasMore: true,
    });
  });
  localStorage.setItem("stickerPacks", JSON.stringify(packsToParse));
};

export const getStickerPacks = () => {
  let isInLO = localStorage.getItem("stickerPacks") || "{}";
  const stickersInLO = JSON.parse(isInLO || {});
  let packs = Object.values(stickersInLO)?.map((sticker) => sticker);
  return packs;
};
