import {
  SET_SIDE_EVENT,
  SET_SIDE_LIST,
  SET_SIDE_TASK,
  SET_SIDE_TODO,
  SOCKETCONNECTED,
  START_SHAKING,
  TOGGLEDRAWER,
} from "../actions/types";
import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  sideList: "chats",
  date: "",
  event: "",
  task: "",
  socketStatus: "disconnected",
  private: false,
  drawerOpen: false,
  shakingChat: null,
};

const global = createReducer(initialState, (builder) => {
  builder
    .addCase(TOGGLEDRAWER, (s, { payload }) => {
      s.drawerOpen = payload;
    })

    .addCase(
      SET_SIDE_LIST,
      (state, { payload: { sideList, private: isPrivate } }) => {
        if (sideList) state.sideList = sideList;
        if (typeof isPrivate === "boolean") state.private = isPrivate;
      }
    )
    .addCase(SET_SIDE_TODO, (state, { payload: { date } }) => {
      state.date = date;
    })
    .addCase(SET_SIDE_EVENT, (state, { payload: { event } }) => {
      state.event = event;
    })
    .addCase(SET_SIDE_TASK, (state, { payload: { task } }) => {
      state.task = task;
    })
    .addCase(SOCKETCONNECTED, (state, { payload }) => {
      state.socketStatus = payload;
    })

    .addCase(START_SHAKING, (state, { payload }) => {
      state.shakingChat = payload;
    });
});

export default global;
