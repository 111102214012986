import {
  SET_CURRENT_USERS,
  CHANGE_USER_STATUS,
  ADD_USER_TO_CONTACT,
} from "../actions/types";
import { createReducer } from "@reduxjs/toolkit";
const initials = {
  users: {},
};

const usersReducer = createReducer(initials, (builder) => {
  builder
    .addCase(ADD_USER_TO_CONTACT, (s, { payload }) => {
      const { name, image, userId } = payload;
      if (s.users[userId]) {
        s.users[userId] = {
          ...s.users[userId],
          contact: {
            image,
            name,
            contactUserId: userId,
          },
        };
      }
    })
    .addCase(SET_CURRENT_USERS, (s, { payload }) => {
      s.users = payload;
    })
    .addCase(CHANGE_USER_STATUS, (s, { payload }) => {
      s.users[payload?.id] = { ...(s.users[payload?.id] || {}), ...payload };
    });
});

export default usersReducer;
