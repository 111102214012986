import { THREADS_ERROR, THREADS_LOADING, GET_THREADS } from "../actions/types";
import { createReducer } from "@reduxjs/toolkit";
const initialState = {
  error: "",
  loading: true,
  data: [],
  byId: {},
  allIds: [],
  pagination: {
    thisPage: 0,
    hasMore: true,
  },
};

const threadsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(THREADS_ERROR, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    })
    .addCase(THREADS_LOADING, (state) => {
      state.error = false;
      state.loading = true;
    })
    .addCase(GET_THREADS, (state, { payload }) => {
      state.data =
        payload?.reset || payload?.pagination?.thisPage === 1
          ? payload?.data
          : [...state?.data, ...payload?.data];

      state.pagination.thisPage = {
        ...(state.pagination.thisPage || {}),
        ...(payload?.pagination?.thisPage || {}),
      };

      state.pagination.hasMore = {
        ...(state.pagination.hasMore || {}),
        ...(payload?.pagination?.hasMore || {}),
      };

      state.loading = false;
    });
});

export default threadsReducer;
