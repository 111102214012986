import {
  EVENTS_LOADING,
  LOAD_EVENTS,
  LOAD_EVENTS_ERROR,
  NEW_EVENT_LOADING,
  NEW_EVENT_ERROR,
  CREATE_NEW_EVENT,
  SINGLE_EVENT_ERROR,
  SINGLE_EVENT_LOADING,
  SINGLE_EVENT_LOAD,
  GET_EVENT_INVITEES,
  GET_EVENT_INVITEES_ERROR,
  GET_EVENT_INVITEES_LOADING,
  UPDATE_EVENT_INVITEES,
  DELETE_EVENT,
  CHANGE_EVENT_STATUS,
  EVENTS_TYPE_LOADING,
  LOAD_EVENTS_TYPE_ERROR,
  LOAD_EVENTS_TYPE,
  EVENT_LIVE_ERROR,
  EVENT_LIVE_LOADING,
  EVENT_LIVE_LOAD,
} from "../actions/types";
import { createReducer } from "@reduxjs/toolkit";
let invitees = {
  loading: false,
  error: false,
  data: [],
  thisPage: 0,
  allPages: 0,
  count: 0,
};

let singleEvent = {
  loading: false,
  error: false,
  data: null,
  invitees,
};

let liveEvent = {
  loading: false,
  error: false,
  data: null,
};

let types = {
  loading: false,
  error: false,
  data: [],
  thisPage: 0,
  allPages: 0,
  count: 0,
};

const initials = {
  data: [],
  thisPage: 0,
  allPages: 0,
  error: false,
  loading: false,
  newLoading: false,
  newError: false,
  singleEvent,
  types,
  liveEvent,
};

const eventReducer = createReducer(initials, (builder) => {
  builder
    .addCase(CHANGE_EVENT_STATUS, (s, { payload = {} }) => {
      const { eventId, status } = payload;
      if (s.singleEvent?.data?.id === eventId) {
        if (status && s.singleEvent.data.status)
          s.singleEvent.data.status.status = status;

        let isFound = s.data?.findIndex((t) => t?.id === eventId);
        if (isFound > -1) {
          let event = s.data[isFound];
          if (event.status && status) s.data[isFound].status.status = status;
        }
      }
    })
    .addCase(DELETE_EVENT, (s, { payload }) => {
      if (s.singleEvent?.data?.id === payload) {
        s.singleEvent = singleEvent;
      }
      s.data = s.data.filter((event) => event.id !== payload);
    })
    .addCase(UPDATE_EVENT_INVITEES, (s, { payload = {} }) => {
      const { eventId, removingUserIds = [], addingUsers } = payload;
      if (eventId === s.singleEvent.data.id && s.singleEvent.invitees) {
        s.singleEvent.invitees.data = [
          ...addingUsers,
          ...(s.singleEvent.invitees.data || []),
        ];
        s.singleEvent.invitees.data = s.singleEvent.invitees.data.filter(
          (assignee) => !removingUserIds.includes(assignee.id)
        );
      }
    })
    .addCase(GET_EVENT_INVITEES_LOADING, (s, { payload }) => {
      s.singleEvent.invitees.loading = true;
    })
    .addCase(GET_EVENT_INVITEES_ERROR, (s, { payload }) => {
      s.singleEvent.invitees.error = payload;
    })
    .addCase(GET_EVENT_INVITEES, (s, { payload }) => {
      s.singleEvent.invitees.data =
        payload?.thisPage === 1
          ? payload?.data
          : [...s?.singleEvent.invitees.data, ...payload?.data];
      s.singleEvent.invitees.count = payload.count;
      s.singleEvent.invitees.allPages = payload.allPages;
      s.singleEvent.invitees.thisPage = payload.thisPage;
      s.singleEvent.invitees.loading = false;
    })
    .addCase(SINGLE_EVENT_ERROR, (s, { payload }) => {
      s.singleEvent.error = payload;
      s.singleEvent.data = null;
      s.singleEvent.loading = false;
    })
    .addCase(SINGLE_EVENT_LOADING, (s) => {
      s.singleEvent.loading = true;
    })

    .addCase(SINGLE_EVENT_LOAD, (s, { payload }) => {
      s.singleEvent.data = payload;
      s.singleEvent.loading = false;
      s.singleEvent.error = "";
    })
    .addCase(CREATE_NEW_EVENT, (s, { payload }) => {
      s.data.unshift(payload);
      s.newLoading = false;
      s.newError = false;
    })
    .addCase(NEW_EVENT_ERROR, (s, { payload }) => {
      s.newError = payload || true;
      s.newLoading = false;
    })
    .addCase(NEW_EVENT_LOADING, (s) => {
      s.newLoading = true;
      s.newError = false;
    })
    .addCase(EVENTS_LOADING, (s) => {
      s.loading = true;
    })
    .addCase(LOAD_EVENTS_ERROR, (s, { payload }) => {
      s.error = payload;
    })
    .addCase(LOAD_EVENTS, (s, { payload }) => {
      s.data =
        payload?.thisPage === 1
          ? payload?.data
          : [...s?.data, ...payload?.data];
      s.count = payload.count;
      s.allPages = payload.allPages;
      s.thisPage = payload.thisPage;
      s.loading = false;
    })
    .addCase(EVENTS_TYPE_LOADING, (s) => {
      s.types.loading = true;
    })
    .addCase(LOAD_EVENTS_TYPE_ERROR, (s, { payload }) => {
      s.types.error = payload;
    })
    .addCase(LOAD_EVENTS_TYPE, (s, { payload }) => {
      s.types.data =
        payload?.thisPage === 1
          ? payload?.data
          : [...s?.types?.data, ...payload?.data];
      s.types.count = payload.count;
      s.types.allPages = payload.allPages;
      s.types.thisPage = payload.thisPage;
      s.types.loading = false;
    })
    .addCase(EVENT_LIVE_LOADING, (s) => {
      s.liveEvent.loading = true;
    })
    .addCase(EVENT_LIVE_ERROR, (s, { payload }) => {
      s.liveEvent.error = payload;
      s.liveEvent.data = null;
      s.liveEvent.loading = false;
    })
    .addCase(EVENT_LIVE_LOAD, (s, { payload }) => {
      s.liveEvent.data = payload;
      s.liveEvent.loading = false;
      s.liveEvent.error = false;
    });
});

export default eventReducer;
