import grey from "@mui/material/colors/grey";

export const light = ({
  primColor,
  secColor,
  primContrastText,
  secContrastText,
}) => ({
  primary: {
    main: primColor || "#25AAE1",
    background: primColor || "#25AAE1",
    default: primColor || "#25AAE1",
    message: primColor || "#25AAE1",
    white: "#fff",
    blackX: "#fff",
    hover: "#857FB3",
    chatBack: `#F9F9F9`,
    contrastText: primContrastText || "#fff",
  },
  secondary: {
    main: secColor || "#ea5069",
    background: primColor || "#25AAE1",
    default: secColor || "#ea5069",
    contrastText: secContrastText || "#fff",
  },
  divider: "#000000",
  text: {
    primary: "#000000",
    secondary: grey[800],
    border: grey[200],
    lightBorder: grey[200],
    icon: grey[500],
    mainGrey: "#FCFCFC",
  },
  background: {
    default: "#fff",
    paper: "#fff",
    menu: "#fff",
    main: "#fff",
    message: primColor || "#25AAE1",
    postFoot: "#ECECEC",
    grey: "#FCFCFC",
    secondary: "#FCFCFC",
    navBtn: "rgba(255,255,255,0.16)",
    authBg: "rgba(255,255,255,0.88)",
    homeBox: "#FBFBFB",
    black: "#202020",
    videoCallBtn: "#FA301F",
    secondaryVideoCallBtn: "#323538",
  },
  main_baack: "#fff",
  appBar: {
    background: "#fff",
  },
  buttonWhite: `#fff`,
});

export const lightBaseline = {
  scrollbarColor: "#6b6b6b #202225",
  "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
    backgroundColor: "#fff",
    width: 1,
    height: 8,
  },
  "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
    borderRadius: 8,
    backgroundColor: "#B1B1B1",
    minHeight: 8,
    border: "3px solid #B1B1B1",
  },
  "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
    backgroundColor: "#B1B1B1",
  },
  "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
    backgroundColor: "#B1B1B1",
  },
  "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#B1B1B1",
  },
  "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
    backgroundColor: "transparent",
  },
};
