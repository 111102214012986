import {
  TEAM_LOADING,
  LOAD_TEAM,
  LOAD_TEAM_ERROR,
  ADD_TEAM,
  EDIT_TEAM,
  DELETE_TEAM,
} from "../actions/types";
import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  thisPage: 0,
  allPages: 0,
  error: false,
  loading: false,
};

const teamReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(TEAM_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(LOAD_TEAM, (state, { payload }) => {
      state.data =
        payload?.thisPage === 1
          ? payload?.data
          : [...state?.data, ...payload?.data];
      state.thisPage = payload.thisPage;
      state.nextPage = payload.nextPage;

      state.loading = false;
    })
    .addCase(LOAD_TEAM_ERROR, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    })
    .addCase(ADD_TEAM, (state, { payload }) => {
      state.data.unshift(payload);
      state.loading = false;
      state.error = false;
    })
    .addCase(EDIT_TEAM, (state, { payload }) => {
      const foundProduct = state.data.filter((e) => e.id === payload?.id)[0];
      const index = state.data.indexOf(foundProduct);
      state.data[index] = { ...state.data[index], ...payload };
    })
    .addCase(DELETE_TEAM, (s, { payload }) => {
      s.data = s.data.filter((team) => team.id !== payload);
      s.loading = false;
    });
});

export default teamReducer;
