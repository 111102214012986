import { configureStore } from "@reduxjs/toolkit";
// import thunkMiddleware from "redux-thunk";
import { chatMediaSlice } from "./reducers/chatMediaSlice";
import {
  auth,
  chats,
  global,
  contacts,
  singleChat,
  events,
  users,
  tasks,
  page,
  posts,
  comments,
  notifications,
  todo,
  pageAnalytics,
  productsCats,
  products,
  cart,
  address,
  payment,
  orders,
  app,
  company,
  livestream,
  specialMember,
  threads,
  searchPage,
  home,
  calendar,
  stage,
  hall,
  team,
  organizationEvent,
  speaker,
  building,
  limitPage,
  services,
  industry,
} from "./reducers";

const store = configureStore({
  reducer: {
    industry,
    services,
    building,
    limitPage,
    speaker,
    organizationEvent,
    app,
    hall,
    stage,
    company,
    auth,
    chats,
    global,
    contacts,
    singleChat,
    events,
    tasks,
    users,
    page,
    posts,
    comments,
    notifications,
    pageAnalytics,
    todo,
    productsCats,
    products,
    cart,
    address,
    payment,
    orders,
    livestream,
    specialMember,
    threads,
    searchPage,
    home,
    calendar,
    team,
    [chatMediaSlice.name]: chatMediaSlice.reducer,
  },
  // middleware: (getDefaultMiddleware) => {
  //   return getDefaultMiddleware().concat(thunkMiddleware);
  // },
  devTools: process.env.NODE_ENV === "production" ? false : true,
});

export default store;
