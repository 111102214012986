import {
  ORGANIZATION_EVENT_LOADING,
  LOAD_ORGANIZATION_EVENT,
  LOAD_ORGANIZATION_EVENT_ERROR,
  ADD_ORGANIZATION_EVENT,
  EDIT_ORGANIZATION_EVENT,
  DELETE_ORGANIZATION_EVENT,
} from "../actions/types";
import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  thisPage: 0,
  allPages: 0,
  error: false,
  loading: false,
};

const organizationEventReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(ORGANIZATION_EVENT_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(LOAD_ORGANIZATION_EVENT, (state, { payload }) => {
      state.data =
        payload?.thisPage === 1
          ? payload?.data
          : [...state?.data, ...payload?.data];
      state.thisPage = payload.thisPage;
      state.nextPage = payload.nextPage;

      state.loading = false;
    })
    .addCase(LOAD_ORGANIZATION_EVENT_ERROR, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    })
    .addCase(ADD_ORGANIZATION_EVENT, (state, { payload }) => {
      state.data.unshift(payload);
      state.loading = false;
      state.error = false;
    })
    .addCase(EDIT_ORGANIZATION_EVENT, (state, { payload }) => {
      const foundProduct = state.data.filter((e) => e.id === payload?.id)[0];
      const index = state.data.indexOf(foundProduct);
      state.data[index] = { ...state.data[index], ...payload };
    })
    .addCase(DELETE_ORGANIZATION_EVENT, (s, { payload }) => {
      s.data = s.data.filter((organizationEvent) => organizationEvent.id !== payload);
      s.loading = false;
    });
});

export default organizationEventReducer;
