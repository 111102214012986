import {
  GET_TODO,
  GET_TODO_ERROR,
  GET_TODO_LOADING,
  DELETE_TODO,
  UPDATE_TODO,
  INSERT_TODO,
  GET_SINGLE_TODO_LOADING,
  GET_SINGLE_TODO,
  GET_TODO_CATEGORY_LOADING,
  GET_TODO_CATEGORY,
  GET_TODO_CATEGORY_ERROR,
  CREATE_TODO_CATEGORY,
  GET_SINGLE_TODO_ERROR,
} from "../actions/types";
import { createReducer } from "@reduxjs/toolkit";
const singleInitial = {
  data: {},
  error: "",
  loading: false,
};
const categoryInitial = {
  data: [],
  error: "",
  loading: false,
  count: 0,
  allPages: 0,
  thisPage: 0,
};
const initialState = {
  error: "",
  loading: false,
  data: {
    byId: {},
    allIds: [],
  },
  count: 0,
  allPages: 0,
  thisPage: 0,
  currentDate: null,
  singleTodo: singleInitial,
  category: categoryInitial,
};
const todoReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(UPDATE_TODO, (s, { payload }) => {
      const isFound = s.data.byId[payload?.id];

      if (isFound) {
        s.data.byId[payload?.id] = {
          ...s.data.byId[payload?.id],
          ...payload,
        };
      }
    })
    .addCase(DELETE_TODO, (s, { payload }) => {
      delete s.data.byId[payload];
      const index = s.data.allIds.indexOf(payload);
      if (index > -1) {
        s.data.allIds.splice(index, 1);
      }
      s.loading = false;
    })
    .addCase(GET_SINGLE_TODO_LOADING, (s) => {
      s.singleTodo.loading = true;
    })
    .addCase(GET_SINGLE_TODO_ERROR, (s, { payload }) => {
      s.singleTodo.loading = false;
      s.singleTodo.error = payload;
    })
    .addCase(GET_SINGLE_TODO, (s, { payload }) => {
      s.singleTodo.data = payload;
      s.singleTodo.loading = false;
    })
    .addCase(GET_TODO_LOADING, (s) => {
      s.loading = true;
      s.error = false;
    })
    .addCase(GET_TODO_ERROR, (s, { payload }) => {
      s.loading = false;
      s.error = payload;
    })
    .addCase(GET_TODO, (s, { payload }) => {
      s.data.byId =
        payload?.thisPage === 1
          ? payload.byId
          : { ...s.data?.byId, ...payload?.byId };
      s.data.allIds =
        payload?.thisPage === 1
          ? payload.allIds
          : [...s.data?.allIds, ...payload?.allIds];
      s.allPages = payload.allPages;
      s.thisPage = payload.thisPage;
      s.loading = false;
    })
    .addCase(GET_TODO_CATEGORY_LOADING, (s) => {
      s.category.loading = true;
      s.category.error = false;
    })
    .addCase(GET_TODO_CATEGORY_ERROR, (s, { payload }) => {
      s.category.loading = false;
      s.category.error = payload;
    })
    .addCase(GET_TODO_CATEGORY, (s, { payload }) => {
      s.category.data =
        payload?.thisPage === 1
          ? payload.data
          : [...s.category.data, ...payload.data];
      s.category.allPages = payload.allPages;
      s.category.thisPage = payload.thisPage;
      s.category.loading = false;
    })
    .addCase(INSERT_TODO, (s, { payload }) => {
      s.data.allIds.unshift(payload?.id);
      s.data.byId[payload?.id] = payload;
      s.loading = false;
      s.error = false;
    })
    .addCase(CREATE_TODO_CATEGORY, (s, { payload }) => {
      s.category.data.unshift(payload);
      s.category.loading = false;
      s.category.error = false;
    });
});

export default todoReducer;
