import {
  GET_SINGLE_PAGE,
  GET_SINGLE_PAGE_LOADING,
  GET_SINGLE_PAGE_ERROR,

  //CATS
  GET_PAGE_CATS,
  DELETE_PAGE_CATS,
  UPDATE_PAGE_CATS,
  CREATE_PAGE_CATS,
  GET_PAGE_MEMBERS,
  GET_PAGE_MEMBERS_LOADING,
  ///
  GET_PAGE_SPEAKERS,
  GET_PAGE_SPEAKERSE_ERROR,
  GET_PAGE_SPEAKERS_LOADING,
  GET_PAGE_RESERVATIONS,
  GET_PAGE_RESERVATIONS_ERROR,
  GET_PAGE_RESERVATIONS_LOADING,
  GET_PAGE_SPONSORS,
  GET_PAGE_SPONSORS_ERROR,
  GET_PAGE_SPONSORS_LOADING,
  ADD_SPONSOR,
  GET_PAGE_BOT,
  GET_PAGE_BOT_LOADING,
  UPDATE_PAGE_DATA,
  ADD_SPEAKER,
  CHANGE_SPEAKER_RATE,
  CHANGE_SPEAKER_BOOKMARK,
  CHANGE_PAGE_CHAT,
  CHANGE_PAGE_TAB,
  UPDATE_POST,
  CHOOSE_POLL_OPTION,
  DELETE_POST,
  CHANGE_REACT,
  EDIT_SPONSOR,
  OPEN_DESIGN_MODE,
  UPDATE_TAB,
  GET_TAB_TYPES,
  GET_TAB_TYPES_ERROR,
  GET_TAB_TYPES_LOADING,
  CREATE_TAB,
  GET_TAB_DATA_TYPES_LOADING,
  GET_TAB_DATA_TYPES_ERROR,
  GET_TAB_DATA_TYPES,
  CREATE_TAB_TYPE,
  CREATE_PARAM,
  TOGGLE_CHATS_WIDGET,
  DELETE_TAB,
  DELETE_CHOICE_POLL_OPTION,
  UPDATE_POLL_OPTION,
} from "../actions/types";
import { createReducer } from "@reduxjs/toolkit";
const initials = {
  designMode: false,
  currentTab: "",
  catsOpen: false,
  page: {
    id: null,
    icons: null,
    iconNames: null,
    userId: null,
    posts: [],
    polls: [],
  },
  chatId: null,
  categories: {
    allPages: 0,
    thisPage: 0,
    data: [],
    loading: true,
    error: false,
  },
  members: {
    allIds: [],
    byId: {},
    allPages: 0,
    thisPage: 0,
    loading: true,
    count: 0,
  },
  speakers: {
    allIds: [],
    byId: {},
    allPages: 0,
    thisPage: 0,
    loading: true,
    count: 0,
    error: false,
  },
  sponsors: {
    allIds: [],
    byId: {},
    allPages: 1,
    thisPage: 1,
    loading: false,
    count: 0,
    error: false,
  },
  reservations: {
    allIds: [],
    byId: {},
    allPages: 1,
    thisPage: 1,
    loading: false,
    count: 0,
    error: false,
  },
  bot: {
    loading: false,
    data: null,
  },
  tabTypes: {
    error: "",
    loading: false,
    data: [],
    thisPage: 0,
    nextPage: 0,
    hasMore: true,
  },
  tabDataTypes: {
    error: "",
    loading: false,
    data: [],
  },
  error: "",
  loading: false,
  chatsWidgetOpen: false,
};

const pageReducer = createReducer(initials, (builder) => {
  builder
    .addCase(CHANGE_PAGE_TAB, (s, { payload }) => {
      s.currentTab = payload.currentTab;
      s.catsOpen = payload.catIsOpen;
    })
    .addCase(CHANGE_PAGE_CHAT, (s, { payload }) => {
      s.chatId = payload.chatId;
    })
    .addCase(GET_PAGE_RESERVATIONS_LOADING, (s) => {
      s.reservations.loading = true;
      s.reservations.error = false;
    })
    .addCase(GET_PAGE_RESERVATIONS_ERROR, (s, { payload }) => {
      s.reservations.loading = false;
      s.reservations.error = payload || true;
    })
    .addCase(GET_PAGE_RESERVATIONS, (s, { payload }) => {
      s.reservations.byId =
        payload?.thisPage === 1
          ? payload.byId
          : { ...s.reservations?.byId, ...payload?.byId };
      s.reservations.allIds =
        payload?.thisPage === 1
          ? payload.allIds
          : [...s.reservations?.allIds, ...payload?.allIds];
      s.reservations.allPages = payload.allPages;
      s.reservations.thisPage = payload.thisPage;
      s.reservations.loading = false;
      s.reservations.count = payload?.count;
      s.reservations.error = false;
    })
    .addCase(CHANGE_SPEAKER_BOOKMARK, (s, { payload }) => {
      let member = s.speakers?.byId[payload?.userId]?.pageMember;
      if (member) {
        s.speakers.byId[payload.userId].pageMember.userBookmark =
          payload?.bookmarkId;
      }
    })
    .addCase(CHANGE_SPEAKER_RATE, (s, { payload }) => {
      let member = s.speakers?.byId[payload?.userId]?.pageMember;
      if (member) {
        s.speakers.byId[payload.userId].pageMember.userRate = payload?.rate;
      }
    })
    .addCase(ADD_SPEAKER, (s, { payload }) => {
      let member = s.members?.byId[payload?.userId]?.pageStatus?.type;
      if (member) {
        s.members.byId[payload.userId].pageStatus.type = 2;
      }
    })
    .addCase(UPDATE_PAGE_DATA, (s, { payload }) => {
      const { title } = payload;
      s.page = { ...s?.page, title };
    })
    .addCase(GET_PAGE_BOT, (s, { payload }) => {
      s.bot.data = payload ? payload : null;
      s.bot.loading = false;
    })
    .addCase(GET_PAGE_BOT_LOADING, (s, { payload }) => {
      s.bot.loading = true;
    })
    .addCase(ADD_SPONSOR, (s, { payload }) => {
      s.sponsors.byId[payload?.id] = { ...payload };
      s.sponsors.allIds.push(payload?.id);
    })
    .addCase(EDIT_SPONSOR, (s, { payload }) => {
      s.sponsors.byId[payload?.id] = { ...payload };
    })
    .addCase(GET_PAGE_SPONSORS_LOADING, (s, { payload }) => {
      s.sponsors.loading = true;
      s.sponsors.error = false;
    })
    .addCase(GET_PAGE_SPONSORS_ERROR, (s, { payload }) => {
      s.sponsors.loading = false;
      s.sponsors.error = payload || true;
    })
    .addCase(GET_PAGE_SPONSORS, (s, { payload }) => {
      s.sponsors.byId =
        payload?.thisPage === 1
          ? payload.byId
          : { ...s.sponsors?.byId, ...payload?.byId };
      s.sponsors.allIds =
        payload?.thisPage === 1
          ? payload.allIds
          : [...s.sponsors?.allIds, ...payload?.allIds];
      s.sponsors.allPages = payload.allPages;
      s.sponsors.thisPage = payload.thisPage;
      s.sponsors.loading = false;
      s.sponsors.count = payload?.count;
      s.sponsors.error = false;
    })
    .addCase(GET_PAGE_MEMBERS_LOADING, (s, { payload }) => {
      s.members.loading = true;
      s.members.error = false;
    })
    .addCase(GET_PAGE_SPEAKERS_LOADING, (s, { payload }) => {
      s.speakers.loading = true;
      s.speakers.error = false;
    })
    .addCase(GET_PAGE_SPEAKERSE_ERROR, (s, { payload }) => {
      s.speakers.loading = false;
      s.speakers.error = payload || true;
    })
    .addCase(GET_PAGE_SPEAKERS, (s, { payload }) => {
      s.speakers.byId =
        payload?.thisPage === 1
          ? payload.byId
          : { ...s.speakers?.byId, ...payload?.byId };
      s.speakers.allIds =
        payload?.thisPage === 1
          ? payload.allIds
          : [...s.speakers?.allIds, ...payload?.allIds];
      s.speakers.allPages = payload.allPages;
      s.speakers.thisPage = payload.thisPage;
      s.speakers.loading = false;
      s.speakers.count = payload?.count;
      s.speakers.error = false;
    })
    .addCase(GET_PAGE_MEMBERS, (s, { payload }) => {
      s.members.loading = false;
      s.members.byId =
        payload?.thisPage === 1
          ? payload.byId
          : { ...s.members?.byId, ...payload?.byId };
      s.members.allIds =
        payload?.thisPage === 1
          ? payload.allIds
          : [...s.members?.allIds, ...payload?.allIds];
      s.members.allPages = payload.allPages;
      s.members.thisPage = payload.thisPage;
      s.members.count = payload?.count;
    })
    .addCase(UPDATE_PAGE_CATS, (state, { payload }) => {
      const index = state.categories.data.findIndex(
        (c) => c.id === payload?.id
      );
      if (index > -1) {
        state.categories.data[index] = { ...payload };
      }
    })
    .addCase(CREATE_PAGE_CATS, (state, { payload }) => {
      state.categories.loading = false;
      state.categories.data.push({ ...payload });
    })
    .addCase(DELETE_PAGE_CATS, (state, { payload }) => {
      const index = state.categories.data.findIndex((c) => c.id === payload[0]);
      if (index > -1) {
        state.categories.data.splice(index, 1);
      }
    })
    .addCase(GET_PAGE_CATS, (state, { payload }) => {
      state.categories.loading = false;
      state.categories.error = false;
      state.categories.chat = payload.chat;
      state.categories = {
        thisPage: payload.thisPage,
        allPages: payload.allPages,
        data:
          payload.thisPage !== 1
            ? [...payload.data, ...state?.categories.data]
            : [...payload.data],
      };
    })
    .addCase(GET_SINGLE_PAGE_LOADING, (s) => {
      s.loading = true;
    })
    .addCase(GET_SINGLE_PAGE, (s, { payload }) => {
      s.page = { ...payload };
      if (payload?.chatId) {
        s.chatId = payload.chatId;
      }
      s.error = "";
      s.loading = false;
    })
    .addCase(GET_SINGLE_PAGE_ERROR, (s, { payload }) => {
      s.page = null;
      s.error = payload;
      s.loading = false;
    }) ////////////////////////////////////////////posts & polls
    .addCase(UPDATE_POST, (s, { payload }) => {
      let postFound = s?.page?.posts.filter((e) => e.id === payload?.postId)[0];
      let pollFound = s?.page?.polls.filter((e) => e.id === payload?.postId)[0];
      if (postFound) {
        const index = s?.page?.posts.indexOf(postFound);
        s.page.posts[index] = payload;
      }
      if (pollFound) {
        const index = s?.page?.polls.indexOf(pollFound);
        s.page.polls[index] = payload;
      }
    })
    .addCase(CHOOSE_POLL_OPTION, (s, { payload }) => {
      let postFound = s?.page?.posts?.filter(
        (e) => e.id === payload?.postId
      )[0];
      let pollFound = s?.page?.polls?.filter(
        (e) => e.id === payload?.postId
      )[0];

      if (postFound?.type?.poll) {
        const index = s?.page?.posts.indexOf(postFound);

        s.page.posts[index].type.poll = payload?.poll;
      }
      if (pollFound?.type?.poll) {
        const index = s?.page?.polls.indexOf(pollFound);

        s.page.polls[index].type.poll = payload?.poll;
      }
    })
    .addCase(UPDATE_POLL_OPTION, (s, { payload }) => {
      let postFound = s?.page?.posts?.filter(
        (e) => e.id === payload?.postId
      )[0];
      let pollFound = s?.page?.polls?.filter(
        (e) => e.id === payload?.postId
      )[0];

      if (postFound?.type?.poll) {
        const index = s?.page?.posts.indexOf(postFound);

        const questionIndex = s.page.posts[
          index
        ].type.poll?.questions?.findIndex(
          (question) => question.id === payload?.questionId
        );

        const optionIndex = s.page.posts[index].type.poll.questions[
          questionIndex
        ]?.options?.findIndex((option) => option.id === payload?.option?.id);

        s.page.posts[index].type.poll.questions[questionIndex].options[
          optionIndex
        ] = payload?.option;
      }
      if (pollFound?.type?.poll) {
        const index = s?.page?.polls.indexOf(pollFound);

        const questionIndex = s.page.polls[
          index
        ].type.poll?.questions?.findIndex(
          (question) => question.id === payload?.questionId
        );

        const optionIndex = s.page.polls[index].type.poll.questions[
          questionIndex
        ]?.options?.findIndex((option) => option.id === payload?.option?.id);

        s.page.polls[index].type.poll.questions[questionIndex].options[
          optionIndex
        ] = payload?.option;
      }
    })
    .addCase(DELETE_CHOICE_POLL_OPTION, (s, { payload }) => {
      let postFound = s?.page?.posts?.findIndex(
        (e) => e.id === payload?.postId
      )[0];
      let pollFound = s?.page?.polls?.findIndex(
        (e) => e.id === payload?.postId
      )[0];

      if (postFound?.type?.poll) {
        const qIndex = s.page.posts[postFound].type.poll?.questions?.findIndex(
          (e) => e?.id === payload?.questionId
        );
        s.page.posts[postFound].type.poll.questions[qIndex].myChosenOption =
          null;
      }
      if (pollFound?.type?.poll) {
        const qIndex = s.page.polls[pollFound].type.poll?.questions?.findIndex(
          (e) => e?.id === payload?.questionId
        );
        s.page.polls[pollFound].type.poll.questions[qIndex].myChosenOption =
          null;
      }
    })
    .addCase(DELETE_POST, (s, { payload }) => {
      s.page.polls = s?.page?.polls.filter((e) => e.id !== payload);
      s.page.posts = s?.page?.posts.filter((e) => e.id !== payload);
    })
    .addCase(CHANGE_REACT, (s, { payload }) => {
      const { deleteReact, postId, reactionTypeId } = payload;
      let postFound = s?.page?.posts.filter((e) => e.id === postId)[0];
      let pollFound = s?.page?.polls.filter((e) => e.id === postId)[0];

      if (postFound) {
        const index = s?.page?.posts.indexOf(postFound);
        const userReaction = s.page.posts[index]["userReaction"];
        if (deleteReact) {
          s.page.posts[index]["userReaction"] = false;
          s.page.posts[index]["totalReactionCount"]--;
          s.page.posts[index]["reacts"]["total"]--;
          s.page.posts[index]["reacts"][userReaction]--;
        } else {
          s.page.posts[index]["userReaction"] = reactionTypeId;
          if (!userReaction) {
            s.page.posts[index]["reacts"]["total"]++;
            s.page.posts[index]["totalReactionCount"]++;
            s.page.posts[index]["reacts"][reactionTypeId]++;
          } else {
            s.page.posts[index]["reacts"][userReaction]--;
            s.page.posts[index]["reacts"][reactionTypeId]++;
            s.page.posts[index]["userReaction"] = reactionTypeId;
          }
        }
      }

      if (pollFound) {
        const index = s?.page?.polls.indexOf(pollFound);
        const userReaction = s.page.posts[index]["userReaction"];
        if (deleteReact) {
          s.page.polls[index]["userReaction"] = false;
          s.page.polls[index]["totalReactionCount"]--;
          s.page.polls[index]["reacts"]["total"]--;
          s.page.polls[index]["reacts"][userReaction]--;
        } else {
          s.page.polls[index]["userReaction"] = reactionTypeId;
          if (!userReaction) {
            s.page.polls[index]["reacts"]["total"]++;
            s.page.polls[index]["totalReactionCount"]++;
            s.page.polls[index]["reacts"][reactionTypeId]++;
          } else {
            s.page.polls[index]["reacts"][userReaction]--;
            s.page.polls[index]["reacts"][reactionTypeId]++;
            s.page.polls[index]["userReaction"] = reactionTypeId;
          }
        }
      }
    })
    .addCase(OPEN_DESIGN_MODE, (s, { payload }) => {
      s.designMode = payload;
    })
    .addCase(UPDATE_TAB, (s, { payload }) => {
      const index = s?.page?.pageTabs?.findIndex((e) => e?.id === payload?.id);
      s?.page?.pageTabs?.splice(index, 1, payload);
    })
    .addCase(CREATE_TAB, (s, { payload }) => {
      s?.page?.pageTabs?.push(payload);
    })
    .addCase(DELETE_TAB, (s, { payload }) => {
      s.page.pageTabs = s?.page?.pageTabs?.filter((e) => e?.id !== payload);
    })
    .addCase(GET_TAB_TYPES_LOADING, (s) => {
      s.tabTypes.loading = true;
    })
    .addCase(GET_TAB_TYPES_ERROR, (s, { payload }) => {
      s.tabTypes.error = payload;
    })
    .addCase(GET_TAB_TYPES, (s, { payload }) => {
      s.tabTypes.data =
        payload?.thisPage === 1
          ? payload?.data
          : [...s.tabTypes?.data, ...payload?.data];

      s.tabTypes.hasMore = payload.hasMore;
      s.tabTypes.nextPage = payload.nextPage;
      s.tabTypes.thisPage = payload.thisPage;
      s.tabTypes.loading = false;
    })
    .addCase(CREATE_TAB_TYPE, (s, { payload }) => {
      s.tabTypes.data.push(payload);
    })
    .addCase(CREATE_PARAM, (s, { payload }) => {
      const index = s.tabTypes?.data?.findIndex(
        (e) => e?.id === payload?.pageTabTypeId
      );
      s.tabTypes?.data[index]?.dataType?.validParams?.push(payload?.data);
    })
    .addCase(GET_TAB_DATA_TYPES_LOADING, (s) => {
      s.tabDataTypes.loading = true;
    })
    .addCase(GET_TAB_DATA_TYPES_ERROR, (s, { payload }) => {
      s.tabDataTypes.error = payload;
    })
    .addCase(GET_TAB_DATA_TYPES, (s, { payload }) => {
      s.tabDataTypes.data = payload;
      s.tabDataTypes.loading = false;
    })
    .addCase(TOGGLE_CHATS_WIDGET, (s, { payload }) => {
      s.chatsWidgetOpen = payload;
    });
});

export default pageReducer;
