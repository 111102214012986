import {
  SEARCH_LOADING,
  SEARCH_ERROR,
  GET_SEARCH,
  CHANGE_SEARCH_TYPE,
  OPEN_SEARCH_ITEM,
  CLOSE_SEARCH_ITEM,
  ADD_SEARCH_EXTRA_TYPE,
  REMOVE_SEARCH_EXTRA_TYPES,
} from "./../actions/types";
import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  error: "",
  loading: false,
  data: {
    privateChats: { data: [], count: 0 },
    groupChats: { data: [], count: 0 },
    threads: { data: [], count: 0 },
    bots: { data: [], count: 0 },
    events: { data: [], count: 0 },
    tasks: { data: [], count: 0 },
    messages: { data: [], count: 0 },
    todos: { data: [], count: 0 },
    pages: { data: [], count: 0 },
    posts: { data: [], count: 0 },
    polls: { data: [], count: 0 },
    eventPosts: { data: [], count: 0 },
  },
  thisPage: 0,
  nextPage: 0,
  hasMore: true,
  singleResult: {},
  type: "all",
  totalCount: 0,
  extraTypes: [],
};

const searchPageReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(SEARCH_LOADING, (state, { payload }) => {
      state.loading = true;
      if (payload === 1) {
        state.thisPage = 0;
        state.totalCount = 0;
      }
    })
    .addCase(SEARCH_ERROR, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    })
    .addCase(GET_SEARCH, (state, { payload }) => {
      if (payload.thisPage === 1) {
        state.singleResult = {};
      }

      Object.keys(payload?.data).forEach((key) => {
        if (payload?.data[key]?.data) {
          state.data[key].data =
            payload?.thisPage === 1
              ? payload?.data[key]?.data
              : [...state?.data[key]?.data, ...payload?.data[key]?.data];
        }
        if (payload?.data[key]?.count >= 0) {
          state.data[key].count = payload?.data[key]?.count;
          state.totalCount += payload?.data[key]?.count;
        }
      });

      state.thisPage = payload.thisPage;
      state.nextPage = payload.nextPage;
      state.hasMore = payload.hasMore;
      state.loading = false;
    })
    .addCase(CHANGE_SEARCH_TYPE, (state, { payload }) => {
      state.type = payload;
      state.extraTypes = [];
    })
    .addCase(ADD_SEARCH_EXTRA_TYPE, (state, { payload }) => {
      state.extraTypes.push(payload);
    })
    .addCase(REMOVE_SEARCH_EXTRA_TYPES, (state, { payload }) => {
      state.extraTypes = [];
    })
    .addCase(OPEN_SEARCH_ITEM, (state, { payload }) => {
      state.singleResult = payload;
    })
    .addCase(CLOSE_SEARCH_ITEM, (state, { payload }) => {
      state.singleResult = {};
    });
});

export default searchPageReducer;
