export const COMPANY_TABS = {
  categories: "categories",
  positions: "positions",
  taskTypes: "task-types",
  rules: "rules",
  people: "people",
  peopleEdit: "people/edit",
  stats: "stats",
  pages: "pages",
  projects: "projects",
  configuration: "configuration",
};
