import {
  LIST_CATEGORIES_LOADING,
  LIST_CATEGORIES_ERROR,
  LIST_CATEGORIES,
  SET_CURRENT_CATEGORY,
  DELETE_CATEGORY,
  ADD_CATEGORY,
  SET_CURRENT_ORGANIZATION,
  SET_CURRENT_ORGANIZATION_ORDER,
} from "./../actions/types";
import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  error: "",
  loading: false,
  data: [],
  currentCategory: null,
  currentOrganization: null,
  currentOrganizationOrder: null,
};

const categoriesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(LIST_CATEGORIES_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(LIST_CATEGORIES_ERROR, (state, { payload }) => {
      state.error = payload;
    })
    .addCase(LIST_CATEGORIES, (state, { payload }) => {
      state.data =
        payload?.thisPage === 1
          ? payload?.data
          : [...state?.data, ...payload?.data];

      // if (payload?.thisPage === 1) {
      //   state.currentCategory = payload?.data[0];
      // }
      state.thisPage = payload.thisPage;
      state.nextPage = payload.nextPage;
      state.loading = false;
    })
    .addCase(ADD_CATEGORY, (state, { payload }) => {
      state.data = [...state.data, payload];
    })
    .addCase(DELETE_CATEGORY, (state, { payload }) => {
      state.data = state.data.filter((e) => e?.id !== payload);
    })
    .addCase(SET_CURRENT_CATEGORY, (state, { payload }) => {
      state.currentCategory = state.data.filter((e) => e.id === payload)[0];
    })
    .addCase(SET_CURRENT_ORGANIZATION, (state, { payload }) => {
      state.currentOrganization = payload;
    })
    .addCase(SET_CURRENT_ORGANIZATION_ORDER, (state, { payload }) => {
      state.currentOrganizationOrder = payload;
    });
});
export default categoriesReducer;
