import {
  LOAD_COMPANY,
  CHANGE_COMPANY_TAB,
  COMPANY_CATEGORIES_GET,
  COMPANY_CATEGORIES_ADD,
  COMPANY_CATEGORIES_DELETE,
  COMPANY_POSITIONS_GET,
  COMPANY_POSITIONS_LOADING,
  COMPANY_POSITIONS_ADD,
  COMPANY_POSITIONS_DELETE,
  COMPANY_POSITIONS_UPDATE,
  COMPANY_POSITIONS_ERROR,
  COMPANY_TASK_TYPE_LOADING,
  COMPANY_TASK_TYPE_ERROR,
  COMPANY_TASK_TYPE_GET,
  COMPANY_TASK_TYPE_ADD,
  COMPANY_TASK_TYPE_DELETE,
  COMPANY_TASK_TYPE_UPDATE,
  COMPANY_PEOPLE_LOADING,
  COMPANY_PEOPLE_ERROR,
  COMPANY_PEOPLE_GET,
  COMPANY_PEOPLE_DELETE,
  COMPANY_PEOPLE_UPDATE,
  COMPANY_PROJECTS_LOADING,
  COMPANY_PROJECTS_ERROR,
  COMPANY_PROJECTS_GET,
  COMPANY_PROJECTS_ADD,
  COMPANY_PROJECTS_DELETE,
  SELECT_PROJECT,
  COMPANY_PROJECTS_UPDATE,
} from "../actions/types";

import { createReducer } from "@reduxjs/toolkit";

import { COMPANY_TABS } from "../../constants/companyTabs";

const initialState = {
  details: null,
  currentTab: COMPANY_TABS.categories,
  employment: null,
  categories: {
    data: [],
    allPages: 0,
    thisPage: 0,
  },
  positions: {
    error: "",
    loading: false,
    data: [],
    thisPage: 0,
    nextPage: 0,
    hasMore: true,
  },
  projects: {
    error: "",
    loading: false,
    data: [],
    thisPage: 0,
    nextPage: 0,
    hasMore: true,
    currentProject: null,
  },
  taskType: {
    error: "",
    loading: false,
    data: [],
    thisPage: 0,
    allPages: 0,
    count: 0,
  },
  people: {
    error: "",
    loading: false,
    data: [],
    thisPage: 0,
    nextPage: 0,
    hasMore: true,
  },
};

const companyReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(LOAD_COMPANY, (s, { payload = {} }) => {
      s.company = payload.company;
      s.employment =
        payload.company?.companyEmployees &&
        payload.company?.companyEmployees[0];
    })
    .addCase(CHANGE_COMPANY_TAB, (s, { payload }) => {
      s.currentTab = payload;
    })
    .addCase(COMPANY_CATEGORIES_GET, (s, { payload }) => {
      s.categories.data = payload.data;
      s.categories.allPages = payload.nextPage;
      s.categories.thisPage = payload.thisPage;
    })
    .addCase(COMPANY_CATEGORIES_ADD, (s, { payload }) => {
      s.categories.data.push(payload);
    })
    .addCase(COMPANY_CATEGORIES_DELETE, (s, { payload }) => {
      s.categories.data = s.categories.data.filter((e) => e.id !== payload);
    })
    .addCase(COMPANY_POSITIONS_LOADING, (s) => {
      s.positions.loading = true;
    })
    .addCase(COMPANY_POSITIONS_ERROR, (s, { payload }) => {
      s.positions.error = payload;
    })
    .addCase(COMPANY_POSITIONS_GET, (s, { payload }) => {
      s.positions.data =
        payload?.thisPage === 1
          ? payload?.data
          : [...s.positions?.data, ...payload?.data];

      s.positions.hasMore = payload.hasMore;
      s.positions.nextPage = payload.nextPage;
      s.positions.thisPage = payload.thisPage;
      s.positions.loading = false;
    })
    .addCase(COMPANY_POSITIONS_ADD, (s, { payload }) => {
      s.positions.data.push(payload);
    })
    .addCase(COMPANY_POSITIONS_DELETE, (s, { payload }) => {
      s.positions.data = s.positions.data.filter((e) => e.id !== payload);
    })
    .addCase(COMPANY_POSITIONS_UPDATE, (s, { payload }) => {
      const positionFound = s.positions.data.filter(
        (e) => e.id === payload?.id
      )[0];
      const index = s.positions.data.indexOf(positionFound);
      s.positions.data[index] = payload;
    })
    .addCase(COMPANY_TASK_TYPE_LOADING, (s) => {
      s.taskType.loading = true;
    })
    .addCase(COMPANY_TASK_TYPE_ERROR, (s, { payload }) => {
      s.taskType.error = payload;
    })
    .addCase(COMPANY_TASK_TYPE_GET, (s, { payload }) => {
      s.taskType.data = payload?.data;
      s.taskType.count = payload.count;
      s.taskType.allPages = payload.allPages;
      s.taskType.thisPage = payload.thisPage;
      s.taskType.loading = false;
    })
    .addCase(COMPANY_TASK_TYPE_ADD, (s, { payload }) => {
      if (s.taskType.thisPage === 1) {
        s.taskType.data.pop();
        s.taskType.data.unshift(payload);
      }
    })
    .addCase(COMPANY_TASK_TYPE_DELETE, (s, { payload }) => {
      s.taskType.data = s.taskType.data.filter((e) => e.id !== payload);
    })
    .addCase(COMPANY_TASK_TYPE_UPDATE, (s, { payload }) => {
      const taskTypeFound = s.taskType.data.filter(
        (e) => e.id === payload?.id
      )[0];
      const index = s.taskType.data.indexOf(taskTypeFound);
      s.taskType.data[index] = payload;
    })
    .addCase(COMPANY_PEOPLE_LOADING, (s) => {
      s.people.loading = true;
    })
    .addCase(COMPANY_PEOPLE_ERROR, (s, { payload }) => {
      s.people.error = payload;
    })
    .addCase(COMPANY_PEOPLE_GET, (s, { payload }) => {
      s.people.data =
        payload?.thisPage === 1
          ? payload?.data
          : [...s.people?.data, ...payload?.data];

      s.people.hasMore = payload.hasMore;
      s.people.nextPage = payload.nextPage;
      s.people.thisPage = payload.thisPage;
      s.people.loading = false;
    })
    .addCase(COMPANY_PEOPLE_DELETE, (s, { payload }) => {
      s.people.data = s.people.data.filter((e) => e.id !== payload);
    })
    .addCase(COMPANY_PEOPLE_UPDATE, (s, { payload }) => {
      const person = s.people.data.filter((e) => e.id === payload.userId)[0];
      const index = s.people.data.indexOf(person);
      person.companyEmployee[0].position = payload.position;
      s.people.data[index] = person;
    })
    .addCase(COMPANY_PROJECTS_LOADING, (s) => {
      s.projects.loading = true;
    })
    .addCase(COMPANY_PROJECTS_ERROR, (s, { payload }) => {
      s.projects.error = payload;
    })
    .addCase(COMPANY_PROJECTS_GET, (s, { payload }) => {
      s.projects.data =
        payload?.thisPage === 1
          ? payload?.data
          : [...s.projects?.data, ...payload?.data];

      s.projects.hasMore = payload.hasMore;
      s.projects.nextPage = payload.nextPage;
      s.projects.thisPage = payload.thisPage;
      s.projects.loading = false;
    })
    .addCase(COMPANY_PROJECTS_ADD, (s, { payload }) => {
      s.projects.data.push(payload);
    })
    .addCase(COMPANY_PROJECTS_DELETE, (s, { payload }) => {
      s.projects.data = s.projects.data.filter((e) => e.id !== payload);
    })
    .addCase(SELECT_PROJECT, (s, { payload }) => {
      s.projects.currentProject = payload;
    })
    .addCase(COMPANY_PROJECTS_UPDATE, (s, { payload }) => {
      const projectFound = s.projects.data.filter(
        (e) => e.id === payload?.id
      )[0];
      const index = s.projects.data.indexOf(projectFound);
      s.projects.data[index] = payload;
    });
});

export default companyReducer;
