// import { initOneSignal } from "../../../oneSignal";
import { getCompanyBySubdomain } from "../../../services/app";
import { getUser } from "../auth";
import { APP_CONFIG, LOAD_COMPANY } from "../types";

const constructSubdomain = () => {
  const subdomain = window.location.host.split(".")[1]
    ? window.location.host.split(".")[0]
    : null;

  return subdomain;
};

const appConfigSync = async (dispatch) => {
  const subdomain = constructSubdomain();

  const { token } = await getUser(dispatch);

  if (!subdomain)
    return {
      company: null,
      subdomain,
    };

  const { company } = await getCompanyBySubdomain({
    subDomain: subdomain,
    token,
  });

  return { company, subdomain, logo: company?.logo };
};

export const appConfigAction = () => (dispatch) => {
  // initOneSignal();

  return appConfigSync(dispatch)
    .then(({ company, subdomain, logo }) => {
      dispatch({ type: APP_CONFIG, payload: { company, subdomain, logo } });
      dispatch({ type: LOAD_COMPANY, payload: { company } });
    })
    .catch((e) => {
      console.error(e);
    });
};
